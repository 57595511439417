import React, { Suspense } from "react";

import { Navbar } from "./components";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Store from './store/app.store';
import { appRoutes } from './Routes';
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import { AppPaths } from "./yts.types";
import ScrollToTop from "./components/scrollToTop";
import Loading from "./components/shared/loading";
import 'react-toastify/dist/ReactToastify.css';

/** need an in-depth review of restructuring of the routes page. */
const App:React.FC = () => {
  const routes:AppPaths[] = appRoutes;
  const routeComponents = routes.map(({ path, component }, key) => <Route path={path} element={component} key={key} />);

  return (
    <>
      <Provider store={Store}>
        <Router>
          <Suspense fallback={<Loading type="inline" height="80px" width="80px" inlineClass="d-flex py-3 py-md-5 justify-content-center" />}>
            <ScrollToTop/>
            <Navbar routes={routes}/>
            <Routes>
              {routeComponents}
            </Routes>
          </Suspense>
        </Router>
      </Provider>
      <ToastContainer />
    </>
  );
}

export default App;
