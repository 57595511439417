class Environment{
    private env:string = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV || 'development';

    get environment():string{
        return this.env;
    }

    get api_base_url():string {
        if(this.env === 'development') return 'http://localhost:8080/api';
        return process.env.REACT_APP_API_BASE_URL || '';
    }

    get api_newsletter_base_url():string {
        if(this.env === 'development') return 'http://localhost:8080/api';
        return process.env.REACT_APP_API_NEWSLETTER_BASE_URL || 'http://localhost:8080/api';
    }

    get api_payments_base_url():string{
        if(this.env === 'development') return 'http://localhost:8080/api';
        return process.env.REACT_APP_API_PAYMENTS_BASE_URL || 'http://localhost:8080/api';
    }

    get graphcms_end_point():string{
        return process.env.REACT_APP_NEXT_PUBLIC_GRAPHCMS_ENDPOINT || process.env.REACT_APP_DEFAULT_GRAPHCMS_ENDPOINT || '';
    }
}

const env = new Environment();

export const environment = env.environment;
export const API_BASE_URL = env.api_base_url;
export const API_NEWSLETTER_BASE_URL = env.api_newsletter_base_url;
export const GRAPHCMS_ENDPOINT = env.graphcms_end_point;