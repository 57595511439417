import React from "react";
import { ILoadingProps } from "../../yts.types";
import './css/loader.css';
import inlineLoadImg from '../../../assets/loader.svg';

const defaultLoadingState: ILoadingProps = {
  type:'standard',
  overlay: true,
  text:'Loading...',
  inlineClass:'w-100',
  styles:'font-size-14 text-muted',
  height: '40px',
  width: '40px'
}

const Loading = (props?:ILoadingProps) => {
  const {type, overlay, text, styles, height, width, inlineClass} = props || defaultLoadingState;

  return (
    <div>
      {type === 'text' ? <Text text={text || 'Loading...'} styles={styles || 'font-size-14 text-muted'}/> : ''}
      {type === 'standard' ? <Standard overlay= {overlay || false}/> : ''}
      {type === 'inline' ? <Inline height={height || '50px'} width={width || '50px'} inlineStyles={inlineClass || ''}/> : ''}

      {type === 'skeleton' ? 
        <>
          <div className="skeleton-wrapper">
            <Skeleton skeletonType="title"/>
            <Skeleton skeletonType="text"/>
            <Skeleton skeletonType="text"/>
            <Skeleton skeletonType="text"/>
          </div>

          <div className="skeleton-wrapper">
            <Skeleton skeletonType="avatar"/>
            <Skeleton skeletonType="title"/>
            <Skeleton skeletonType="text"/>
          </div>
        </> : ''}
    </div>
  );
}

const Skeleton = (props: ISkeletonProps) => {
  const {skeletonType} = props;
  const classes= `skeleton ${skeletonType}`;
  return (
    <div className={classes}></div>
  );
}

const Text = (props:ITextProps) => {
  const {text, styles} = props;
  return (
    <span className={`${styles}`}>{text}</span>
  );
}

const Inline = (props:IInlineProps)=> {
  const {height, width, inlineStyles} = props;
  return (
    <>
      <div className={`${inlineStyles || ''}`}>
        <img loading="lazy" width={width} height={height} src={inlineLoadImg}/>
      </div>
    </>
  );
}

const Standard = (props:IStandardProps) => {
  const {overlay} = props;
  return (
    <div className={overlay ? 'overlay d-flex align-items-center justify-content-center' : ''} style={{backgroundColor: overlay ?  "rgba(145, 145, 145, 0.6)" : 'transparent', zIndex: overlay ? 2000 : 1}}>
      <div className="lds-ellipsis">
        <div></div><div></div><div></div><div></div>
      </div>
    </div>
  );
}

interface ISkeletonProps {
  skeletonType: 'avatar' | 'title' | 'text';
}

interface IStandardProps{
  overlay: boolean
}

interface IInlineProps {
  height:string,
  width:string
  inlineStyles?:string,
}

interface ITextProps {
  styles:string,
  text:string,
}

export default Loading;