import React from 'react'
import mLogo from '../../assets/logo.micro.png';

export const Logo:React.FC = () => {
    return (
        <div className='d-flex align-items-center'>
            <img height="42px" src={mLogo} alt="YoursthatSenior micro logo" /> 
            <div className='d-flex flex-column' style={{marginLeft: '4px'}}>
                <p className='m-0 font-nunito text-dark' style={{fontSize:'15.5px', letterSpacing: '0.5px', marginBottom: 2}}>YoursthatSenior</p>
                <p className='m-0 font-nunito text-muted' style={{fontSize:'9.5px', letterSpacing: '0.3px', lineHeight: '1.5'}}>The one who guides you.</p>
            </div>
        </div>
    )
}
