import { lazy } from 'react';

export const Home = lazy(() => import('./Home'));
export const Maintenance  = lazy(() => import('./Maintenance'));
export const  NotFound= lazy(() => import('./NotFound'));
export const  Blog = lazy(() => import('./Blog'));
export const  BlogDetails= lazy(() => import('./BlogDetails'));
export const  Ticket = lazy(() => import('./Ticket'));
export const  CourseRegistration= lazy(() => import('./courseRegistration/CourseRegistration'));
export const  Registration = lazy(() => import('./courseRegistration/Registration'));
export const  Checkout = lazy(() => import('./Checkout/Checkout'));
export const  CourseView = lazy(() => import('./Course'));
export const  Legals = lazy(() => import('./legals'));
export const  BookCounselling = lazy(() => import('./BookCounselling'));
export const Career = lazy(() => import('./Career'));
export const FullStack = lazy(() => import('./FullStack'))
export const About = lazy(() => import('./About'));
export const Support = lazy(() => import('./Support'));
export const CourseDGM = lazy(() => import('./CourseDGM'))