import { gql } from "graphql-request";

export const getBlogsQuery = gql`
query MyQuery {
  postsConnection {
    edges {
      node {
        author {
          bio
          id
          name
          photo {
            url
          }
        }
        createdAt
        slug
        title
        excerpt
        featuredImage {
          url
        }
        content{
          html
        }
        categories {
          name
          slug
        }
      }
    }
  }
}      
`;


export const getSingleBlogUsingSlugQuery = gql`
    query MyQuery($slug: String!) {
      post(where: {slug: $slug}) {
        author {
          bio
          id
          name
          photo {
            url
          }
        }
        createdAt
        slug
        title
        excerpt
        featuredImage {
          url
        }
        content{
          html
        }
        categories {
          name
          slug
        }
      }
    }      
  `;