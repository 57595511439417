export const smartTrim = (str: string, maxLength = 45) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.substring(0, maxLength) + '...';
};

export const formatDate = (date: any): string => { return new Date(date*1000).toLocaleString("en-US", {timeZone: 'Asia/Kolkata'})}

export const convertCamelCaseToNormalTxt = (value:string): string =>{
  const regex = /([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g, output = value.replace(regex, '$& ');
  return output.charAt(0).toUpperCase() + output.slice(1);
}

export const convertNormalTxtToCamelCase = (str: string): string => {
  return str.toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

export const validateEmail = (email:string):boolean => {
 return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(email);
}

export const validatePhone = (phone:number | string):boolean => {
  const mob_regex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
  return mob_regex.test(phone.toString());
}

export const validatePincode = (pincode:number | string):boolean => {
  if(!pincode) return false;
  const pincode_regex = /^[1-9][0-9]{5}$/;
  return pincode_regex.test(pincode.toString());
}

export const calculateAge = (birthday:any) => {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  return ageDate.getUTCFullYear() - 1970;
}

export const validateDOB = (DOB: Date | string | number, minAge = 5):boolean =>{
  return calculateAge(DOB) - minAge >= 0;
}

export const formatIndianCurrency = (amount?:number):string=>{
  if(!amount) return '₹ 0.00';
  return `₹ ${amount.toLocaleString('en-IN')}`
}
