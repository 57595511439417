import { applyMiddleware, createStore } from "redux";
import appReducer from "./app.reducer";
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { environment } from "../../environment";

const logger = createLogger({
    predicate: (getState, action) => !(environment === 'production')
});

// Implementation details - https://redux-toolkit.js.org/usage/usage-with-typescript
const Store = createStore(appReducer, composeWithDevTools(applyMiddleware(logger)));
export type AppState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch;

export default Store;