import React, { memo } from 'react'
import Footer from '../components/home/footer'

const Legals:React.FC<{title: string, content: string}> = ({title, content}):JSX.Element => {
  return (
      <>
        <section className='container'>
              <h4 className='display-6 text-main mt-4'>{title}</h4>
            <p className='my-3 text-muted' dangerouslySetInnerHTML={{__html: content}}></p>
        </section>
        <Footer/>
      </>
  )
}

export default memo(Legals);

export const REFUND_AND_SCHEDULE_POLICY = `
<div class="font-size-15">
    <p class="text-dark font-size-16">Thank you for purchasing courses from YoursThatSenior.</p>
    <p class="font-size-15 text-muted">
      Please see the information below for more information on our refund and rescheduling policies. Please keep in mind that our policies for single workshop purchases and workshop packs differ. Please keep in mind that we take violations of our Terms of Service very seriously, and we are under no obligation to provide refunds to Users who break these or other YoursThatSenior policies.
    </p>
    
    <p class="font-size-15">
      <strong>Course Purchase:</strong>
      <br>
      YoursThatSenior strives to provide students with a seamless learning experience, but if you are dissatisfied with the workshop quality, please contact us at care@yoursthatsenior.com  for assistance. Unfortunately, we only offer a full refund for our Course if we haven't provided you with access to the Workshop within 60 days of the beginning of your workshop month. The Company will issue refunds at its sole discretion.
    </p>
    
    <p>
      <strong>Third-Party Purchase:</strong> <br> We are unable to process your refund if you purchased the Course from a third-party vendor. We don't have the transaction on file because we didn't process the original payment, so we can't initiate a refund for you. If applicable, you may contact the third-party vendor directly to request a refund.
    </p>
    
    <p>
      <strong>Refund on Token Amount:</strong> <br> Unfortunately, we do not provide refunds on token amounts paid to book your seat in any Course. You may get in contact with us at  care@yoursthatsenior.com  for the redressal of the issue.
    </p>
    
    <p>
      <strong>Course rescheduling:</strong> <br> If you are unable to attend the Course during the scheduled month, you may reschedule your Workshop at any time prior to the Workshop's start date.
    </p>
    
    <p>
      You must request a month change within the first ten days of the Course if you wish to reschedule after the Workshop begins (after the 1st of the month). For more information, please contact us through our support channel.
    </p>
    
    <p>
      <strong>Contact us </strong>  
      Please contact us if you have any questions about our Refunds and Rescheduling Policy: The request must be sent to care@yoursthatsenior.com  with your email address and phone number used during registration or by calling 8580543034
    </p>
</div>
`;

export const TERMS_OF_SERVICE_HTML_CONTENT = `
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The Company ensures steady commitment to Your usage of the Platform and
    privacy with regard to the protection of Your invaluable information. This
    document contains information about the Website and domain “</span
  ><a
    href="http://www.yoursthatsenior.com"
    data-mce-href="http://www.yoursthatsenior.com"
    ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
      >www.yoursthatsenior.com</span
    ></a
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;">
    ” (hereinafter referred to as the “Platform”).</span
  >
</p>
<p>
  <strong
    >For the purpose of these Terms of Use (“Terms”), wherever the context so
    requires,</strong
  >
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      "We," "Our," and "Us" shall refer to the Domain and/or the Company,
      depending on the context.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      "You," "Yourself," and "User" refer to natural and legal persons who use
      the Platform and are legally capable of entering into binding contracts
      under Indian law.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      "Services" means providing a platform that allows users to host their
      own online courses and earn a royalty for each sale. Users can access
      the content via videos, texts, and slides. Clause 3 of these Terms of
      Use will provide a more detailed explanation.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      "Third Parties" refers to any application, company, or person other than
      the User and the Platform's creator. It will include payment gateways
      with which the Company has partnered.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The term "Platform" refers to the Company's website/domain, which allows
      the Client to use the platform to access the Company's services.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      "User" refers to the people who will be using the platform to make
      posts.</span
    >
  </li>
</ol>
<p></p>
<p>
  <strong>
    Their teachings, as well as the people who use the platform to learn
    from the Live sessions.
  </strong>
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The section headings in these Terms are only for the purpose of
      organising the various provisions under these Terms in an orderly
      manner, and neither Party shall use them to interpret the provisions
      contained herein in any way. Furthermore, the Parties have expressly
      agreed that the headings have no legal or contractual
      significance.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The Users' use of this Platform is governed solely by these Terms, as
      well as the Privacy Policy and other policies listed on the Platform, as
      well as any modifications or amendments made by the Company, at its sole
      discretion, from time to time. You agree to comply with and be bound by
      the following Terms and Conditions of Use and Our Privacy Policy if you
      continue to access and use this Platform. The User expressly agrees and
      acknowledges that these Terms and Policy are co-terminus in nature,
      meaning that if one expires or terminates, the other will as well.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The User unambiguously agrees that these Terms and the aforementioned
      Policy are a legally binding agreement between the User and the Company,
      and that the User will be bound by the rules, guidelines, policies,
      terms, and conditions that apply to any service provided by the
      Platform, and that the same will be deemed incorporated into these Terms
      and treated as part and parcel of the same. The User acknowledges and
      agrees that these Terms and the Policy are not binding on the User
      unless and until the User signs or expressly agrees to them, and that
      the User's act of visiting any part of the Platform constitutes the
      User's full and final acceptance of these Terms and the aforementioned
      Policy.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The Company reserves the sole and exclusive right to amend or modify
      these Terms at any time without prior notice or permission from the
      User, and the User expressly agrees that any such amendments or
      modifications will take effect immediately. The User is responsible for
      checking the terms on a regular basis and staying up to date on their
      requirements. If the User continues to use the Platform after such a
      change, the User will be deemed to have agreed to any and all changes
      made to the Terms. The User is granted a personal, non-exclusive,
      non-transferable, revocable, limited privilege to access and use the
      Platform and Services if the User complies with these Terms. If the User
      refuses to accept the changes,You must stop using the Services at
      once.</span
    >
  </li>
</ol>
<p></p>
<p>
  <strong>
    Continued use of the Services will signify your acceptance of the
    changed terms.
  </strong>
</p>
<ol>
  <li><strong> REGISTRATION</strong></li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Registration is mandatory for all Users in order to use the platform or
      even view the services online.
    </span>
  </li>
</ol>
<p><strong>General Terms</strong></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The Users are also provided with an option to link their Facebook/Google
      accounts with the Platform at the time of their registration in order to
      smoothen the process of registration.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Registration for this Platform is available for all age groups, barring
      those “Incompetent to Contract” which inter alia include insolvents with
      an exception to Minors.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Further, at any time during Your use of this Platform, including but not
      limited to the time of registration, You are solely responsible for
      protecting the confidentiality of Your Username and password, and any
      activity under the account shall be deemed to have been done by You. In
      the case that you provide us with false and/or inaccurate details or we
      have reason to believe you have done so, we hold the right to
      permanently suspend your account. You agree that you will not disclose
      your password to any third party and that you will take sole
      responsibility for any activities or actions under your account, whether
      or not you have authorised such activities or actions. You will
      immediately notify us of any hereinbelow use of your account.</span
    >
  </li>
</ol>

<strong> PLATFORM OVERVIEW</strong>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The platform operates under the domain name of
    “http://www.yoursthatsenior.com”. The platform is a market-place for
    everyone who has the knowledge to share or a skill to teach.</span
  >
</p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The platform operates under the domain name of </span
  ><a
    href="http://www.yoursthatsenior.com"
    data-mce-href="http://www.yoursthatsenior.com"
    ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
      >http://www.yoursthatsenior.com</span
    ></a
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;">
    ” . The platform is a market-place for everyone who has the knowledge to
    share or a skill to teach, to coach inspired individuals wanting to learn.
    Learning should never cease to be part of life. Hence, we aspire to have
    teachers come host courses on our platform and coach a generation of
    learners. We at YoursThatSenior, believe that teaching is not meant only
    for conventional teachers, but in the idea that anyone and everyone can
    teach (and learn).</span
  >
</p>

<strong> ELIGIBILITY</strong>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The Users further represents that they will comply with this Agreement
    and all applicable local, state, national and international laws, rules
    and regulations.</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The Users may not use the Platform if they are not competent to contract
    or are disqualified from doing so by any other applicable law, rule or
    regulation currently in force.</span
  >
</p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >inspired individuals wanting to learn. Learning should never cease
    to be part of life. Hence, we aspire to have teachers come host courses on
    our platform and coach a generation of learners. We at YoursThatSenior ,
    believe that teaching is not meant only for conventional teachers, but in
    the idea that anyone and everyone can teach (and learn).</span
  >
</p>
<p></p>

<strong> CONTENT</strong>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;">
    All text, graphics, User interfaces, visual interfaces, photographs,
    trademarks, logos.
  </span>
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >brand names, descriptions, sounds, music and artwork (collectively,
    ‘Content’), is generated/provided by Platform and the Platform has control
    over it and assures a reasonable quality, the accuracy, integrity or
    genuineness of products sold on the Platform.</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >All the Content displayed on the Platform is subject to copyright and
    shall not be reused by any party (or a third party) without the prior
    written consent of the Company and the copyright owner.</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The Users are solely responsible for the integrity, authenticity, quality
    and genuineness of the feedback and comments by Users can be made via the
    Platform, the Platform bears no liability whatsoever for any feedback or
    comments made by the Users or made in respect of any of the content on the
    Platform. Further, the Platform reserves its right to suspend the account
    of any User for an indefinite period to be decided at the discretion of
    the Platform or to terminate the account of any User who is found to have
    created or shared or submitted any Content or part thereof that is found
    to be untrue/inaccurate/misleading or offensive/vulgar. The User shall be
    solely responsible for making good any financial or legal losses incurred
    through the creation/sharing/submission of Content or part thereof that is
    deemed to be untrue/inaccurate/misleading.</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The Users have a personal, non-exclusive, non-transferable, revocable,
    limited privilege to access the Content on the Platform. Users shall not
    copy, adapt, and modify any content without the written permission of the
    Company.</span
  >
</p>
<p></p>
<p><strong>TERM</strong></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      a. These Terms shall continue to form a valid and binding contract
      between the Parties and shall continue to be in full force and effect
      until the User continues to access and use the Platforms.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      b. The Users may terminate their use of the Platform at any time.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      c. The Company may terminate these Terms and close a User’s account at
      any time without notice and/or suspend or terminate a User’s access to
      the Platform at any time and for any reason, if any discrepancy or legal
      issue arises.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      d. Such suspension or termination shall not limit our right to take any
      other action against you that the Company considers appropriate.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      e. It is also hereby declared that the Company may discontinue the
      Services and Platforms without any prior notice.</span
    >
  </li>
</ol>
<p></p>

<strong> TERMINATION</strong>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The Company reserves the right, in its sole discretion, to
      unilaterally terminate the User’s access to the Platform, or any portion
      thereof, at any time, without notice or cause.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The Platform also reserves the universal right to deny access to
      particular Users, to any/all of are on its Platform without any prior
      notice/explanation in order to protect the interests of the Platform
      and/or other visitors to the Platform.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The Platform reserves the right to limit, deny or create different
      access to the Platform and its features with respect to different Users,
      or to change any of the features or introduce new features without prior
      notice.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The User shall continue to be bound by these Terms, and it is
      expressly agreed to by the Parties that the User shall not have the
      right to terminate these Terms till the expiry of the same.</span
    >
  </li>
</ol>
<p></p>

<strong> COMMUNICATION</strong>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >By using this Platform and providing his/her identity and contact
    information to the Company through the Platform, the Users hereby agree
    and consent to receive calls, e-mails or SMS from the Company and/or any
    of its representatives at any time.</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Clients can report to “</span
  ><a
    href="mailto:care@yoursthatsenior.com"
    data-mce-href="mailto:care@yoursthatsenior.com"
    ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
      >care@yoursthatsenior.com</span
    ></a
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;">
    ” if they find any discrepancy with regard to Platform or content-related
    information and the Company will take necessary action after an
    investigation. The response with resolution (if any issues found) shall be
    dependent on the time taken for investigation.</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The User expressly agrees that notwithstanding anything contained
    hereinabove, it may be contacted by the Company or any representatives
    relating to any Product purchased by the User on the Platform or anything
    pursuant thereto and the Users agrees to indemnify the Company from any
    and all harassment claims. It is expressly agreed to by the Parties that
    any information shared by the User with the Company shall be governed by
    the Privacy Policy.</span
  >
</p>
<p></p>

<strong> PAYMENT</strong>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Charges</span
  >
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The User viewing the services listed on the platform is free of cost
      however any such services availed by the User through the platform shall
      be subject to payments and the User is bound to make a payment to the
      company. The Company has third party payment gateways like</span
    >
  </li>
</ol>
<p></p>
<ul>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Google Pay Business</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Razor pay</span
    >
  </li>
</ul>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The Company will not be liable for any credit or debit card fraud. The
    liability to use a card fraudulently will be on the user and the onus to
    'prove otherwise' shall be exclusively on the user. To provide a safe and
    secure shopping experience, the Company regularly monitors transactions
    for fraudulent activity. In the event of detecting any suspicious
    activity, the Company reserves the right to cancel all past, pending and
    future orders without any liability.</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The COmpany shall disclaim all responsibility and owns no liability to
    Users for any outcome (incidental, direct, indirect or otherwise) from the
    use of the Services. The Company, as a merchant, shall be under no
    liability whatsoever in respect of any loss or damage arising directly or
    indirectly out of the decline of authorization for any Transaction, on
    Account of the Cardholder having exceeded the preset limit mutually agreed
    by us with our acquiring bank from time to time.</span
  >
</p>
<p></p>

<strong> USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT</strong></p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The Client agrees and acknowledges that they are a restricted user of
    this Platform and that they:</span
  >
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Agree to provide genuine credentials during the process of registration
      on the Platform. You shall not use a fictitious identity to register.
      The Company is not liable if the User has provided incorrect
      information.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Agree to ensure the Name, Email address, Address, Mobile number and any
      such other information provided during account registration is valid at
      all times and shall keep your information accurate and up-to-date. The
      User can update their details anytime through accessing their profile on
      the platform.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Agree that they are solely responsible for maintaining the
      confidentiality of your account password. You agree to notify us
      immediately of any unauthorised use of your account. The company
      reserves the right to close your account at any time for any or no
      reason.</span
    >
  </li>
</ol>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The User also acknowledges the fact that data entered into the database
      is for the purpose of easy and ready reference for the User, and to
      streamline the Services through the Platform.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Authorise the Platform to use, store or otherwise process certain
      personal information and all published Content, Client responses, Client
      locations, User comments, reviews, and ratings for personalization of
      Services, marketing, and promotional purposes and for optimisation of
      User-related options and Services.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Understand and agree that, to the fullest extent permissible by law, the
      Platform/Company and their successors and assigns, or any of their
      affiliates or their respective officers, directors, employees, agents,
      licensors, representatives, operational service providers, advertisers
      or suppliers shall not be liable for any loss or damage, of any kind,
      direct or indirect, in connection with or arising from use of the
      Platform or from this terms of use, including, but not limited to,
      compensatory, consequential, incidental, indirect, special or punitive
      damages.
    </span>
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Are bound not to cut, copy, modify, recreate, reverse engineer,
      distribute, disseminate, post, publish or create derivative works from,
      transfer, or sell any information or obtained from the Platform. Any
      such use/limited use of the Platform will only be allowed with the prior
      express written permission of the Company.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Agree not to access (or attempt to access) the Platform and/or the
      materials or Services by any means other than through the interface
      provided by the Platform. The use of deep-link, robot, spider or other
      automatic devices, program, algorithm or methodology, or any similar or
      equivalent manual process, to access, acquire, copy or monitor any
      portion of the Platform or its content, or in any way reproduce or
      circumvent the navigational structure or presentation of the Platform,
      materials or any content, or to obtain or attempt to obtain any
      materials, documents or information through any means not specifically
      made available through the Platform will lead to suspension or
      termination of the User’s access to the Platform. The User acknowledges
      and agrees that by accessing or using the Platform or any of the
      Services provided therein, it may be exposed to content that it may
      consider offensive, indecent or otherwise objectionable. The Company
      disclaims any and all liabilities arising in relation to such offensive
      content on the Platform.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Expressly agree and acknowledge that the Content generated by the User
      and displayed on the Platform is not owned by the Company and that the
      Company is in no way responsible for the content of the same. The User
      may, however, report any offensive or objectionable content, which the
      Company may then remove from the Platform, at its sole discretion.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Expressly consents to follow the terms and conditions, and policies of
      the Vendor affiliated with the Company from whom the Users are utilising
      services.</span
    >
  </li>
</ol>
<p></p>
<p><strong>The User further undertakes not to:</strong></p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Engage in any activity that interferes with or disrupts access to the
      Platform or the Services provided therein (or the servers and networks
      which are connected to the Platform);</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Impersonate any person or entity, or falsely state or otherwise
      misrepresent his/her affiliation with a person or entity;</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Probe, scan or test the vulnerability of the Platform or any network
      connected to the Platform, nor breach the security or authentication
      measures on the Platform or any network connected to the Platform. The
      User may not reverse look-up, trace or seek to trace any information
      relating to any other User of, or visitor to, the Platform, or any other
      viewer of the Platform, including any User account maintained on the
      Platform not operated/managed by the User, or exploit the Platform or
      information made available or offered by or through the Platform, in any
      manner;</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Disrupt or interfere with the security of, or otherwise cause harm to,
      the Platform, systems resources, accounts, passwords, servers or
      networks connected to or accessible through the Platform or any
      affiliated or linked Platforms;</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Use the Platform or any material or content therein for any purpose that
      is unlawful or prohibited by these Terms, or to solicit the performance
      of any illegal activity or other activity which infringes the rights of
      this Platform or any other third party (s);</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Violate any code of conduct or guideline which may be applicable for or
      to any particular service offered on the Platform;</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Violate any applicable laws, rules or regulations currently in force
      within or outside India;</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Violate any portion of these Terms or the Privacy Policy, including but
      not limited to any applicable additional terms of the Platform contained
      herein or elsewhere, whether made by amendment, modification, or
      otherwise;</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Commit any act that causes the Company to lose (in whole or in part) the
      Services of its Internet Establishment ("ISP") or in any manner disrupts
      the Services of any other supplier/service provider of the
      Company/Platform;</span
    >
  </li>
</ol>
<p></p>
<p><strong>Further:</strong></p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The User hereby expressly authorises the Company/Platform to disclose
      any and all information relating to the User in the possession of the
      Company/Platform to law enforcement or other government officials, as
      the Company may in its sole discretion, believe necessary or appropriate
      in connection with the investigation and/or resolution of possible
      crimes, especially those involve personal injury and theft/infringement
      of intellectual property. The User further understands that the
      Company/Platform might be directed to disclose any information
      (including the identity of persons providing information or materials on
      the Platform) as necessary to satisfy any judicial Order, law,
      regulation or valid governmental request.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      By indicating User's acceptance to purchase any product or service
      offered on the site, the user is obligated to complete such transactions
      after making payment. Users shall prohibit from indicating their
      acceptance to avail services where the transactions have remained
      incomplete.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The User agrees to use the services provided by the Company, its
      affiliates, consultants and contracted companies, for lawful purposes
      only.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The User agrees not to make any bulk purchase to indulge in any resale
      activities. In case of any such instances, the Company reserves all
      rights to cancel the current and future orders and block the concerned
      User account.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The User agrees to provide authentic and true information. The Company
      reserves the right to conCompany and validate the information and other
      details provided by the User at any point of time. If upon
      conCompanyation such User details are found to be false, not to be true
      (wholly or partly), the Company shall in its sole discretion reject the
      registration and debar the User from using the Services available on its
      Website, and/or other affiliated websites without prior intimation
      whatsoever.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The User agrees not to post any material on the website that is
      defamatory, offensive, obscene, indecent, abusive, or needlessly
      distressful, or advertising any goods or services. More specifically,
      the User agrees not to host, display, upload, update, publish, modify,
      transmit, or in any manner share any information that:</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      i. belongs to another person and to which the User has no right
      to;</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      ii. is grossly harmful, harassing, blasphemous, defamatory, obscene,
      pornographic, paedophilic, libellous, invasive of another's privacy,
      hateful, or racially, ethnically objectionable, disparaging, relating or
      encouraging money laundering or gambling, or otherwise unlawful in any
      manner.</span
    >
  </li>
</ol>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;">9 </span
  ><strong>whatever;</strong>
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >iii. is in any way harmful to minors;</span
  >
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      infringes any patent, trademark, copyright or other proprietary
      rights;</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      violates any law for the time being in force;</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      deceives or misleads the addressee about the origin of such messages or
      communicates any information which is grossly offensive or menacing in
      nature;</span
    >
  </li>
</ol>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >vii. Abuse, harass, threaten, defame, disillusion, erode, abrogate,
    demean or otherwise violate the legal rights of others;</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >viii. Impersonate any person or entity, or falsely state or otherwise
    misrepresent Your affiliation with a person or entity;</span
  >
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Publish, post, disseminate, any information which is grossly harmful,
      harassing, blasphemous, defamatory, obscene, pornographic, paedophilic,
      libellous, invasive of another's privacy, hateful, or racially,
      ethnically objectionable, disparaging, relating or encouraging money
      laundering or gambling, or otherwise unlawful in any manner whatever; or
      unlawfully threatening or unlawfully harassing including but not limited
      to "indecent representation of women" within the meaning of the Indecent
      Representation of Women (Prohibition) Act, 1986;</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Threatens the unity, integrity, defence, security or sovereignty of
      India, friendly relations with foreign states, or public order or causes
      incitement to the commission of any cognizable offence or prevents
      investigation of any offence or is insulting any other nation.</span
    >
  </li>
</ol>
<p></p>
<ol start="11">
  <li><strong> SUSPENSION OF USER ACCESS AND ACTIVITY.</strong></li>
</ol>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Notwithstanding other legal remedies that may be available, the Company
    may in its sole discretion, limit the User’s access and/or activity by
    immediately removing the User’s access credentials either temporarily or
    indefinitely, or suspend/terminate the User’s association with the
    Platform, and/or refuse to usage of the Platform to the User, without
    being required to provide the User with notice or cause:</span
  >
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      If the User is in breach any of these Terms or the Policy;</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      If the User has provided wrong, inaccurate, incomplete or incorrect
      information;</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      If the User’s actions may cause any harm, damage or loss to the other
      Users or to</span
    >
  </li>
</ol>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;">10</span>
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >the Company, at the sole discretion of the Company.</span
  >
</p>
<p></p>
<ol start="12">
  <li><strong> INDEMNITY</strong></li>
</ol>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The Users of this Platform agree to indemnify, defend and hold harmless
    the Company/Platform, and their respective directors, officers, employees
    and agents (collectively, "Parties"), from and against any and all losses,
    liabilities, claims, damages, demands, costs and expenses (including legal
    fees and disbursements in connection therewith and interest chargeable
    thereon) asserted against or incurred by us that arise out of, result
    from, or may be payable by virtue of, any breach or non-performance of any
    representation, warranty, covenant or agreement made or obligation to be
    performed pursuant to these terms of use. Further, the User agrees to hold
    the Company/Platform harmless against any claims made by any third party
    due to, or arising out of, or in connection with:</span
  >
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      User’s use of the Platform,</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      User’s violation of these Terms and Conditions;</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      User’s violation of any rights of another;</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      User’s alleged improper conduct pursuant to these Services;</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      User’s conduct in connection with the Platform;</span
    >
  </li>
</ol>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >User agrees to fully cooperate in indemnifying the Company and the
    Platform at the user’s expense. The user also agrees not to reach a
    settlement with any party without the consent of the Company.</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >In no event shall the Company/Platform be liable to compensate the User
    or any third party for any special, incidental, indirect, consequential or
    punitive damages whatsoever, including those resulting from loss of use,
    data or profits, whether or not foreseeable, and whether or not the
    Company/ Platform had been advised of the possibility of such damages, or
    based on any theory of liability, including breach of contract or
    warranty, negligence or other tortuous action, or any other claim arising
    out of or in connection with the User’s use of or access to the Platform
    and/or the Services or materials contained therein.</span
  >
</p>
<p></p>
<ol start="13">
  <li><strong> LIMITATION OF LIABILITY</strong></li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      The Founders/ Promoters/ Partners/ Associated people of the
      Company/Platform are not responsible for any consequences arising out of
      the following events:</span
    >
  </li>
</ol>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;">11</span>
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      1. i. If the Platform is inoperative/non-responsive due to any
      connectivity errors associated with the internet connection such as but
      not limited to slow connectivity, no connectivity, server failure;</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      1. ii. If the User has fed incorrect information or data or for any
      deletion of data;</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      1. iii. If there is an undue delay or inability to communicate through
      email;</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      1. iv. If there is any deficiency or defect in the Services managed by
      Us;</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      1. v. If there is a failure in the functioning of any other service
      provided by the Platform.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      b. The Platform accepts no liability for any errors or omissions, on
      behalf of itself, or for any damage caused to the User, the User’s
      belongings, or to any third party, resulting from the use or misuse of
      the Platform or any service availed of by the User through the Platform.
      The service and any Content or material displayed on the service is
      provided without any guarantees, conditions or warranties as to its
      accuracy, suitability, completeness or reliability. The Platform will
      not be liable to you for the unavailability or failure of the
      Platform.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      c. Users are to comply with all laws applicable to them or to their
      activities, and with all Policies, which are hereby incorporated into
      this Agreement by reference.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      d. The Platform expressly excludes any liability for any loss or damage
      that was not reasonably foreseeable by the Platform and which is
      incurred by you in connection with the Platform, including loss of
      profits; and any loss or damage incurred by you as a result of your
      breach of these terms.</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      e. To the fullest extent permitted by law, the Platform shall not be
      liable to you or any other party for any loss or damage, regardless of
      the form of action or basis of any claim. You acknowledge and agree that
      your sole and exclusive remedy for any dispute with us is to terminate
      your use of the Platform.</span
    >
  </li>
</ol>
<p></p>
<ol start="14">
  <li><strong> INTELLECTUAL PROPERTY RIGHTS</strong></li>
</ol>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Unless expressly agreed to in writing, nothing contained herein shall
    give the User a right to use any of the Platform’s, trademarks, service
    marks, logos, domain names, information, questions, answers, solutions,
    reports and other distinctive brand features, save according to the
    provisions of these Terms. All logos, trademarks, brand names, service
    marks, domain names, including material, designs, and graphics created by
    and developed by the Platform and other distinctive brand features of the
    Platform are the property of the Company or the respective copyright or
    trademark owner. Furthermore, with respect to the Platform created
    by</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >12 the Company, the Company shall be the exclusive owner of all the
    designs, graphics and the like, related to the Platform.</span
  >
</p>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The User may not use any of the intellectual property displayed on the
    Platform in any manner that is likely to cause confusion among existing or
    prospective Users of the Platform, or that in any manner disparages or
    discredits the Company/Platform, to be determined in the sole discretion
    of the Company.</span
  >
</p>
<p></p>
<ol start="15">
  <li><strong> DISCLAIMER OF WARRANTIES AND LIABILITIES</strong></li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      a. The User agrees and undertakes that they are accessing the Platform
      at their sole risk and are using their best and prudent judgement before
      availing any service listed on the Platform or accessing/using any
      information displayed thereon.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      b. The User agrees that any kind of information, resources, activities,
      recommendations obtained/availed from Platform, written or oral, will
      not create any warranty and the Platform disclaims all liabilities
      resulting from these.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      c. The Company/Platform does not guarantee that the Services contained
      in the Platform will be uninterrupted or error-free, or that the
      Platform or its server will be free of viruses or other harmful
      components, and the User hereby expressly accepts any and all associated
      risks involved with the User’s use of the Platform.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      d. It is further agreed to by the Parties that the contents of this
      Section shall survive even after the termination or expiry of the Terms
      and/or Policy.</span
    >
  </li>
</ol>
<p></p>
<ol start="16">
  <li><strong> FORCE MAJEURE</strong></li>
</ol>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Neither the Company nor the Platform shall be liable for damages for any
    delay or failure to perform its obligations hereunder if such delay or
    failure is due to cause beyond its control or without its fault or
    negligence, due to Force Majeure events including but not limited to acts
    of war, acts of God, earthquake, riot, fire, festive activities sabotage,
    labour shortage or dispute, internet interruption, technical failure,
    breakage of sea cable, hacking, piracy, cheating, illegal or
    unauthorised.</span
  >
</p>
<p></p>
<ol start="17">
  <li><strong> DISPUTE RESOLUTION AND JURISDICTION</strong></li>
</ol>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >It is expressly agreed to by the Parties hereto that the formation,
    interpretation and performance of these Terms and any disputes arising
    therefrom will be resolved through a two-step Alternate Dispute Resolution
    (“ADR”) mechanism. It is further agreed to by the Parties that the
    contents of this Section shall survive even after the termination or
    expiry of the Terms and/or Policy.</span
  >
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Mediation: In case of any dispute between the parties, the Parties will
      attempt to resolve the same amicably amongst themselves, to the mutual
      satisfaction of all Parties. In the event that the Parties are unable to
      reach such an amicable solution within thirty (30) days of one Party
      communicating the existence of a dispute to any other Party, the dispute
      will be resolved by arbitration, as detailed hereinbelow;</span
    >
  </li>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Arbitration: In the event that the Parties are unable to amicably
      resolve a dispute by mediation, said dispute will be referred to
      arbitration by a sole arbitrator to be appointed by the Company, and the
      award passed by such sole arbitrator will be valid and binding on all
      Parties. The Parties shall bear their own costs for the proceedings,
      although the sole arbitrator may, in his/her sole discretion, direct
      either Party to bear the entire cost of the proceedings. The arbitration
      shall be conducted in English, and the seat of Arbitration shall be
      Telangana, India.</span
    >
  </li>
</ol>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >The Parties expressly agree that the Terms of Use, Privacy Policy and any
    other agreements entered into between the Parties are governed by the
    laws, rules and regulations of India.</span
  >
</p>
<p></p>
<ol start="18">
  <li><strong> NOTICES</strong></li>
</ol>
<p></p>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Any and all communication relating to any dispute or grievance
    experienced by the User may be communicated to the Company by the User by
    emailing to “</span
  ><a
    href="mailto:care@yoursthatsenior.com"
    data-mce-href="mailto:care@yoursthatsenior.com"
    ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
      >care@yoursthatsenior.com</span
    ></a
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;">
    ”.</span
  >
</p>
<p></p>
<ol start="19">
  <li><strong> MISCELLANEOUS PROVISIONS</strong></li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      a. Entire Agreement: These Terms, read with the Policy, form the
      complete and final contract between the User and the Company with
      respect to the subject matter hereof and supersedes all other
      communications, representations and agreements (whether oral, written or
      otherwise) relating thereto.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      b. Waiver: The failure of either Party at any time to require
      performance of any provision of these Terms shall in no manner affect
      such Party's right at a later time to enforce the same. No waiver by
      either party of any breach of these Terms, whether by conduct or
      otherwise, in any one or more instances, shall be deemed to be or
      construed as a further or continuing waiver of any such breach, or a
      waiver of any other breach of these Terms.</span
    >
  </li>
</ol>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      c. Severability: If any provision/clause of these Terms is held to be
      invalid, illegal or unenforceable by any court or authority of competent
      jurisdiction, the validity, legality and enforceability of the remaining
      provisions/clauses of these Terms shall in no way be affected or
      impaired thereby, and each such provision/clause of these Terms
      shall</span
    >
  </li>
</ol>
<p>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >be valid and enforceable to the fullest extent permitted by law. In such
    case, these Terms shall be reformed to the minimum extent necessary to
    correct any invalidity, illegality or unenforceability, while preserving
    to the maximum extent the original rights, intentions and commercial
    expectations of the Parties hereto, as expressed herein.</span
  >
</p>
<p></p>
<ol>
  <li>
    <span style="font-weight: 400" data-mce-style="font-weight: 400;">
      Contact Us: If you have any questions about this Policy, the practices
      of the Platform ,or your experience with the Service provided by the
      Platform, you can contact us at </span
    ><a
      href="mailto:care@yoursthatsenior.com"
      data-mce-href="mailto:care@yoursthatsenior.com"
      ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
        >care@yoursthatsenior.com</span
      ></a
    ><span style="font-weight: 400" data-mce-style="font-weight: 400;">
      .</span
    >
  </li>
</ol>
<p></p>
`;

export const PRIVACY_POLICY_HTML_CONTENT = `
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >Yoursthatsenior EdTech respects and understands the importance of your
  privacy - and is therefore committed to affording complete protection to
  the personal information of our customers who use our products and
  services or visitors of our website, and of the learners whose Data we may
  have access to and when you apply on our website for an open position. In
  recognition of this need for security, our commitment to</span
>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >General Data Protection Regulation</span
>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >and Secured management of any information received /collected by
  Yoursthatsenior EdTech has established this Privacy Policy.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  ></span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >This privacy policy applies to our websites – www.yoursthatsenior.com and
  our products, including the Appitest platform and any services we provide.
  It also applies to our marketing practices. We ensure total transparency
  in all our dealings.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >Data Privacy is important to us, and we strive to be transparent in our
  data collection and use practices.</span
>
</p>
<h4><strong></strong></h4>
<h4>
<strong>WHAT INFORMATION IS COLLECTED?</strong>
</h4>
<ol>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Personal information collected on our website.</span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    ></span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >As a general practice, no Personal information is collected about a
    visitor to the website, apart from the personal information submitted by
    the visitor in a 'contact us form' or 'submit a resume' link. We shall
    use such Personal Information for the purpose you share it with us.
    However, at times, certain information may be collected from you when
    you visit the website. Such information is aggregated to measure the
    number of visits, average time spent on the website, pages viewed, etc.
    This gives us an idea of which parts of our website users are visiting
    so that we may improve the content to offer you better services. We do
    not link IP addresses to anything personally identifiable. This means
    that a user's session will be tracked, but the user will be
    anonymous.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >ID, Customer information for support and maintenance.</span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    ></span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >When you register to use a Yoursthatsenior EdTech product, create an ID
    with us, or use our services for development or support, we collect
    unique information and identify you. This may include your name, email
    address, phone number, or payment information, all by applicable law.
    Some information is required, and other information is optional.
    Sometimes eligibility information may be required. At times we may rely
    on information from public sources.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Information is collected using our online products and applications,
    especially during activation and automatic updates.</span
  >
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >When our product/application is used, information may be collected on
    its use. We may collect information (like an IP address or the type of
    device used) that your browser or device typically sends to our servers
    whenever you use our website, product, or application. We may use
    information that led you to our website through cookies and similar
    technologies, for example, the search terms used or the device being
    used. Often, this information is anonymous and kept strictly
    confidential if associated with you. If you do not want us to collect
    this information, you can tell us by opting out or declining, or
    customising cookie settings on our website.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Yoursthatsenior EdTech emails</span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    ></span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >We contact organisations or executives as a part of our marketing
    campaigns. We do so as we believe we have a legitimate interest in
    promoting our products and services, which also benefits our prospects.
    We do not mass mailing and carefully reach out to selected professionals
    with a personalised approach. As part of our marketing campaigns, the
    emails we send you to allow us to know if you have received or opened
    the email or clicked a link within the email. If you do not want us to
    collect this information from marketing emails, you can opt-out of
    receiving such emails by clicking on the unsubscribe button.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Buttons or tools on our websites related to other companies and social
    media pages</span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;">.</span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    ></span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Our websites may include buttons or tools that link to other companies'
    services (for example, a Facebook or Google button). We may collect
    information about your use of these features. In addition, when you see
    or interact with these buttons, tools, or content or view a
    Yoursthatsenior EdTech web page containing them, some information from
    your browser may automatically be sent to the other company. Please read
    that company's privacy policy for more details, as these sites may have
    their privacy statements in place, which we recommend you review if you
    visit any linked Web sites. We are not responsible for the content of
    linked sites or any use of the sites or the privacy practices of those
    other Web sites. We may collect information when you interact with our
    social networking pages or use the sign-on features.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Hosted services provided by Yoursthatsenior EdTech.</span
  >
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    ></span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >We provide hosted services to many companies that may sometimes require
    collecting information through cookies and other technology to collect
    analytic data needed for the product and to understand our users and
    their likely interests. No information is collected without
    consent.</span
  >
</li>
</ol>
<p></p>
<h4>
<strong
  >WHERE
  DO WE STORE THIS DATA?</strong
>
</h4>
<p></p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >Information and data files are stored on our servers and the servers of
  companies we hire to provide services to us. We do not share, sell or
  lease any information collected to any third parties for their marketing
  uses.</span
>
</p>
<p></p>
<h4>
<strong
  >HOW
  DO WE USE THE INFORMATION COLLECTED?</strong
>
</h4>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >We use this information to:</span
>
</p>
<ul>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >To fulfil our contractual commitment and enhance our contract's
    performance with you.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Allow your use of our products and applications and respond to your
    requests.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Provide you with the services, support or information requested and
    monitor the effectiveness.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Improve our website's user experience and communicate better to engage
    users</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Enhance the effectiveness of our marketing campaigns. To send marketing
    emails or messages related to our products and services.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Monitor aggregate metrics to conduct our market research and understand
    our customers better.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >To process your job application for an open position.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Analyse, diagnose and fix issues in our product and service
    offerings.</span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    ><br
  /></span>
</li>
</ul>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >In all cases, this information is not shared with any third party. Magic
  EdTech uses it as above or to prevent/respond to the protection of our
  websites or applications. In some cases, like with hosted services, we may
  share information with those that provide us with technology services
  (e.g., web hosting and analytics services) but strictly to carry out our
  work. All such vendors used by us are General Data Protection Regulation
  compliant and have signed a Data protection agreement with Magic to secure
  your data. We may be required to share information with law enforcement or
  other third parties when compelled to do so by court order or different
  legal processes to comply with statutes or regulations.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >If we merge with or are acquired by another company, and if all of our
  assets are acquired by another company, this information will most likely
  be one of the assets to be transferred. However, we will not share our
  customers' personal information until necessary to provide you with a
  continuity of service and only when the new owner maintains and provides
  the same level of data privacy standards. In such cases, we will provide
  you with notice and an opportunity to opt-out of the transfer of
  identifiable data.</span
>
</p>
<p></p>
<p><strong>HOW DO WE SECURE INFORMATION COLLECTED?</strong></p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >We understand that the security of your information is vital and have in
  place administrative solid, technical, and physical security controls and
  measures to keep data safe and secure. In addition, our privacy practices
  are designed to protect your personal information worldwide.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >To protect information stored in our servers through Amazon Web Service
  infrastructure at various locations like the US, Australia, and India,
  access is limited (through user/password credentials and two-factor
  authentication) to those employees who require it to perform their job
  functions. In addition, we use industry-standard Secure Socket Layer (SSL)
  encryption technology to safeguard the account registration process and
  sign-up information. Other security safeguards include but are not limited
  to multifactor authentication, data encryption, firewalls, and physical
  access controls to buildings and files.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >We want to caution our visitors about phishing attacks, wherein
  unscrupulous third parties seek to extract sensitive and confidential
  information from you by posing as a genuine website or by sending an email
  misrepresenting it to be from an authentic source. We never seek sensitive
  or personal information regarding your financial or health record through
  emails or our websites. Therefore, if you receive such a message claiming
  to be from Yoursthatsenior EdTech, please do not reply and immediately
  bring it to our attention by contacting the Webmaster.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >Yoursthatsenior EdTech also recognizes the receipt, transmission, or
  distribution of spam emails (unsolicited bulk emails) as a significant
  concern and has taken reasonable measures to minimise the transmission and
  effect of spam emails in its computing environment.</span
>
</p>
<h4><strong></strong></h4>
<h4>
<strong
  >CAN
  THIS INFORMATION BE REVIEWED?</strong
>
</h4>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >In some cases and at our discretion, this information may be accessed by
  respective organisations to correct any mistake in that information and to
  delete any data we no longer have business reasons for retaining. You can
  do this by sending us an email. Yoursthatsenior EdTech strives to comply
  with all applicable laws around the globe that are designed to protect
  your privacy and information, no matter where that information is
  collected, transferred or retained. Although legal requirements may vary
  from country to country, we intend to adhere to the principles outlined in
  this Privacy Policy even if the information is transferred from your
  country to other countries that may not require an adequate level of
  protection for your input.</span
>
</p>
<h4>
<strong
  ></strong
>
</h4>
<p></p>
<h4>
<strong
  >RIGHTS OVER
  YOUR DATA</strong
>
</h4>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >Under certain circumstances, by law, you have the right to:</span
>
</p>
<ul>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Be informed about the processing of your data (i.e., for what purposes,
    what types, to what recipients it is disclosed, storage periods, any
    third party sources from which it was obtained, confirmation of whether
    we undertake automated decision-making, including profiling, and the
    logic, significance and envisaged consequences).</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Object to your Data being processed for a particular purpose or request
    that we stop using your information.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Request not to be subject to a decision based on automated processing
    and have safeguards if you are being profiled based on your data.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Ask us to transfer a copy of your data to you or to another service
    provider or third party where technically feasible and otherwise
    required by applicable regulations.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Withdraw, at any time, any consent that you have previously given to us
    for our use of your data.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Ask us to stop or start sending you marketing messages at any
    time.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Request access to your Data (commonly known as a "data subject access
    request"). This enables you to receive a copy of the personal
    information we hold about you and check that we are lawfully processing
    it.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Request correction of the personal data that we hold about you. This
    enables you to have any incomplete or inaccurate information we hold
    about you corrected. The personal Data we hold about you must be
    accurate and current. Please keep us informed if your Data changes
    during your working relationship with us.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Request the erasure of your personal data. This enables you to ask us
    to delete or remove personal data where you think we do not have the
    right to process it.</span
  >
</li>
<li
  style="font-weight: 400"
  data-mce-style="font-weight: 400;"
>
  <span style="font-weight: 400" data-mce-style="font-weight: 400;"
    >Any request for access to or a copy of your data must be in writing. We
    will endeavor to respond within a reasonable period and within one month
    in compliance with Data Protection Legislation. We will provide this
    information free unless the request is manifestly unfounded or
    excessive. We will comply with our legal obligations regarding any
    individual's rights as a data subject.</span
  ><span style="font-weight: 400" data-mce-style="font-weight: 400;"
    ><br
  /></span>
</li>
</ul>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >If you would like to contact us about any of the rights set out above,
  please contact us by email </span
><span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >at care@yoursthatsenior.com</span
><span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >. We may take reasonable steps to verify your identity before providing
  you with the details to protect your privacy and security.</span
>
</p>
<h4><strong></strong></h4>
<p></p>
<h4>
<strong
  >NOTIFICATION
  OF CHANGES</strong
>
</h4>
<p></p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >If we decide to change our privacy policy, we will post those changes on
  this page, so our users are always aware of the information we collect and
  how we use it. If, at any point, we decide to use personally identifiable
  information in a manner different from that stated at the time it was
  collected, we will notify users by way of an email. Users will have a
  choice as to whether or not we use their information in this different
  manner. We will use information following the privacy policy under which
  the information was collected.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >Where links are provided to other websites, it should be noted that they
  are not and cannot be governed by our Privacy Statement. Therefore, we
  cannot guarantee your privacy when you access other websites through any
  link provided on this website.</span
>
</p>
<p></p>
`;

export const DISCLAIMER_HTML_CONTENT = `
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >We, YoursthatSenior Pvt. Ltd., A Company incorporated under the Companies
  Act, 2013 having its office at Flat No. 829, Pocket Gh-2, Baghban
  Apartments, Sector-28, Rohini, Delhi-110042, represented by its members,
  hereinafter referred to as the “Company” (where such expression shall,
  unless repugnant to the context thereof, be deemed to include its
  respective legal heirs, representatives, administrators, permitted
  successors and assigns).</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >The Company will not be responsible for any damage suffered by users from
  use of the services on this site. This includes but is not limited to,
  loss of revenue/data resulting from delays, non-deliveries, missed
  deliveries, or service interruptions as may occur because of any
  act/omission of parties. This disclaimer of liability also applies to any
  damages or injury caused by any failure of performance, error, omission,
  interruption, deletion, defect, delay in operation or transmission,
  computer virus, communication line failure, theft or destruction or
  unauthorised access to, alteration of, or use of record, whether for
  breach of contract, tortuous behaviour, negligence, or under any other
  cause of action.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >The Company also makes it clear that it shall not be held liable for any
  damage/hurt/inconvenience caused to the User through the course of the
  provision of Services or as a result of the Users’ actions. The Company
  makes no representations or warranties as to the conduct of the Users. The
  Company takes no responsibility for any content that Users send or receive
  from other Users or ThirdParty posts through the Website or Platform. Any
  material downloaded or otherwise obtained through use of the service is
  accessed at the Users own discretion and risk.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >The services on the Platform are intended to be subject to availability,
  without any promises or guarantees on the same by the company, and while
  certain information available on the Platform is the property of the
  Company and the Company endeavours to keep the said information updated
  and accurate, the Company shall not make any representations or warranties
  of any kind, express or implied, about 2 the completeness, accuracy,
  reliability, suitability or availability with respect to the Platform or
  the information, services, or related graphics contained on the Platform
  for any purpose. Any reliance you place on such information is therefore
  strictly at your own risk.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >The Company bears no responsibility whatsoever for any consequences
  arising from the use of the said Services by Users. The use of the
  Services in the Platform is the sole responsibility of the User (or
  legally authorised person on behalf of the User), and in case of any
  negligence on the part of the User is acting on the same shall not be
  construed as imposing any liability, direct or indirect, on the Company
  /Platform.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >The Company shall disclaim all responsibility and owns no liability to
  Users for any outcome (incidental, direct, indirect or otherwise) from the
  use of the Platform or any such services under the platform. In no event
  will the Company be liable for any loss or damage including without
  limitation, indirect or consequential loss or damage, or any loss or
  damage whatsoever arising from loss of data or profits arising out of, or
  in connection with, the use of the Platform.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >Through this Platform, You may be able to access the link of other
  Platforms which are not under the control of the Company or the Platform.
  We have no control over the nature, content and availability of those
  sites. The inclusion of any links does not necessarily imply a
  recommendation or endorse the views expressed within them. Every effort is
  made to keep the Platform up and running smoothly. However, the Company
  takes no responsibility for, and will not be liable for, the Platform
  being temporarily unavailable due to technical issues beyond our
  control.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE WILL HAVE NO
  LIABILITY RELATED TO USER CONTENT ARISING UNDER INTELLECTUAL PROPERTY
  RIGHTS, LIBEL, PRIVACY, PUBLICITY, OBSCENITY OR OTHER LAWS. WEBSITE ALSO
  DISCLAIMS ALL LIABILITY WITH RESPECT TO THE MISUSE, LOSS, MODIFICATION OR
  UNAVAILABILITY OF ANY USER CONTENT.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >THE USER UNDERSTANDS AND AGREES THAT ANY MATERIAL OR DATA DOWNLOADED OR
  OTHERWISE OBTAINED THROUGH THE PLATFORM IS DONE ENTIRELY AT HIS/HER OWN
  DISCRETION AND RISK AND HE/SHE WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
  TO HIS/HER COMPUTER 3 SYSTEMS OR LOSS OF DATA THAT RESULTS FROM THE
  DOWNLOAD OF SUCH MATERIAL OR DATA. WE vARE NOT RESPONSIBLE FOR ANY
  TYPOGRAPHICAL ERROR LEADING. PLATFORM ACCEPTS NO LIABILITY FOR ANY ERRORS
  OR OMISSIONS, WITH RESPECT TO ANY INFORMATION PROVIDED TO YOU WHETHER ON
  BEHALF OF ITSELF OR THIRD PARTIES.</span
>
</p>
<p>
<span style="font-weight: 400" data-mce-style="font-weight: 400;"
  >THE ADVERTISEMENT THAT MAY BE AVAILABLE ON E-MAIL OR WEBSITE WITH RESPECT
  TO THE THIRD-PARTY WEBSITE OR THE PRODUCTS AND SERVICES ARE FOR
  INFORMATION PURPOSE ONLY.</span
>
</p>
`;