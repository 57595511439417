import React, { memo, useState } from 'react'
import { appRoutes } from '../../Routes';
import { HOMEPAGE_CONSTANTS } from '../../constants/home';

import footerImg from '../../../assets/footer-bg.png';
import { IContactUsDetails } from '../../yts.types';
import { toast } from 'react-toastify';
import { ERROR_TOAST_OPTIONS, SUCCESS_TOAST_OPTIONS, WARNING_TOAST_OPTIONS } from '../../util/constants';
import { convertCamelCaseToNormalTxt, validateEmail, validatePhone } from '../../util';
import { submitContactUsApplication, subscribeToNewsletter } from '../../service/nl.service';
import { handleHttpError } from '../../service/api/httpHandler';
import Marquee from "react-fast-marquee";

import P1 from '../../../assets/partners/partner_1.png';
import P2 from '../../../assets/partners/partner_2.png';
import P3 from '../../../assets/partners/partner_3.png';
import P4 from '../../../assets/partners/partner_4.png';
import P5 from '../../../assets/partners/partner_5.png';
import P6 from '../../../assets/partners/partner_6.png';
import P7 from '../../../assets/partners/partner_7.jpg';
import P8 from '../../../assets/partners/partner_8.jpg';

import { useMediaQuery } from 'react-responsive';
import WhyNotYTS from './whyNotYTS';
import { Logo } from '../logo';

const Footer:React.FC = () => {
    const currentYear = new Date().getFullYear(); 
    const socialMediaVal = HOMEPAGE_CONSTANTS.socialMediaIcons;
    const isMobile = useMediaQuery({ query: `(max-width: 900px)` });

    const primaryRoutes = appRoutes.filter(val => (val.title && val?.title?.length > 0 && !val?.footerSubList));
    const footerSubsetListRoutes = appRoutes.filter(val => (val.title && val?.title?.length > 0 && val?.footerSubList));

    const [contactUsApplication, setContactUsApplication] = useState<IContactUsDetails>({name:'', message:'', email:'',subject:'', phone: ''});
    const [contactUsProcessing, setContactUsProcessing] = useState(false);

    const [newsletterEmail, setNewsletterEmail] = useState<string>('')
    const [newsletterProcessing, setNewsletterProcessing] = useState(false);

    const updateContactFormVal = (key: string, value: string) => {
        setContactUsApplication({...contactUsApplication, [key]:value});
    }

    const submitNewsletterEmail = (event:  React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!validateEmail(newsletterEmail) || !newsletterEmail?.length){
            toast('Please provide a valid email.', ERROR_TOAST_OPTIONS);
            return;
        }

        setNewsletterProcessing(true);
        subscribeToNewsletter(newsletterEmail).then(res=>{
            setNewsletterProcessing(false);
            if(res.data.success){
                setNewsletterEmail('');
                toast('Newsletter subscription added.', SUCCESS_TOAST_OPTIONS);
                return;
            }
            toast('Something went wrong', ERROR_TOAST_OPTIONS);
        }).catch(err => {
            handleHttpError(err, 'You are already subscribed');
            setNewsletterProcessing(false);
        })
    }

    const submitContactUsRequest = (event: React.FormEvent<HTMLFormElement>):void => {
        event.preventDefault();
        const val:any = contactUsApplication;
        const contactUsApplicationErrors:string[] = [];
        Object.keys(val).forEach(key => {
            if(typeof val[key] === 'string' && val[key].trim() === ''){
                contactUsApplicationErrors.push(`${convertCamelCaseToNormalTxt(key)} is required`);
            }
        });

        if(!validateEmail(contactUsApplication.email)) contactUsApplicationErrors.push(`'${contactUsApplication.email}' is not a valid email.`);
        if(!validatePhone(contactUsApplication.phone)) contactUsApplicationErrors.push(`'${contactUsApplication.phone}' is not a valid phone number.`);

        contactUsApplicationErrors.forEach(err => toast(err,ERROR_TOAST_OPTIONS));
        if(contactUsApplicationErrors.length > 0) return;
        
        setContactUsProcessing(true);
        submitContactUsApplication(contactUsApplication).then(res =>{
            setContactUsProcessing(false);
            if(res.data.success){
                toast('We have received your application.', SUCCESS_TOAST_OPTIONS);
                setContactUsApplication({name:'', message:'', subject:'', email:'', phone:''});
            } else {
                toast('Something went wrong', WARNING_TOAST_OPTIONS);
            }
        }).catch(err=>{
            handleHttpError(err);
            setContactUsProcessing(false);
        });
    }

    const partnerImages = [P1,P2,P3,P4,P5,P6,P7,P8];

    return (
    <div>
        <WhyNotYTS/>
        <section id='partner'>
            <div className="container">
                <header className="section-header">
                    <p>Our Experts &amp; Partners</p>
                </header>
                <Marquee
                    className='pointer py-5'
                    speed={isMobile ? 40 : 50}
                    loop={0}
                    play={true}
                    gradient={!isMobile}
                    pauseOnHover={true}
                    direction='left'
                >
                    {partnerImages.map((img, idx) => <img key={idx} style={{maxHeight: isMobile ? 50 : 80}} className='mx-md-4 mx-3 rounded img-fluid' src={img}/>)}
                </Marquee>
            </div>
        </section>
        <section id="contact" className="contact">
            <div className="container">

            <header className="section-header">
                <h2>Contact</h2>
                <p>Contact Us</p>
            </header>
            <div className="row gy-4">
                <div className="col-lg-6">
                <div className="row gy-4">
                    <div className="col-md-6">
                    <div className="info-box rounded-lg h-100">
                        <i className="fa fa-map-marker-alt"></i>
                        <h3>Address</h3>
                        <p>
                            Rohini, New Delhi, DELHI-110042
                        </p>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="info-box rounded-lg h-100">
                        <i className="fa fa-phone-alt"></i>
                        <h3>Call Us</h3>
                        <p>
                            we are happy to get in touch with you on
                            <a className="text-dark pr-1" href="tel:+918580543034"> +91-8580543034</a> 
                        </p>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="info-box rounded-lg">
                        <i className="fa fa-envelope"></i>
                        <h3>Email Us</h3>
                        <p>care@yoursthatsenior.com</p>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="info-box rounded-lg">
                        <i className="fa fa-clock"></i>
                        <h3>Open Hours</h3>
                        <p>We are Always Available</p>
                    </div>
                    </div>
                </div>

                </div>

                <div className="col-lg-6">
                    <form onSubmit={(en) => submitContactUsRequest(en)} className="php-email-form">
                        <div className="row gy-4">
                            <div className="col-md-6">
                                <input type="text" name="name" className="form-control" autoComplete='off' required value={contactUsApplication.name} onChange={(e)=>updateContactFormVal('name', e.target.value)} placeholder="Your Name"/>
                            </div>

                            <div className="col-md-6">
                                <input type="email" className="form-control" autoComplete='off' required value={contactUsApplication.email} onChange={(e)=>updateContactFormVal('email', e.target.value)} name="email" placeholder="Your Email"/>
                            </div>

                            <div className="col-md-12">
                                <input type="tel" className="form-control" autoComplete='off' required value={contactUsApplication.phone} onChange={(e)=>updateContactFormVal('phone', e.target.value)} name="phone" placeholder="999XXXX000"/>
                            </div>

                            <div className="col-md-12">
                                <input type="text" className="form-control" autoComplete='off' required value={contactUsApplication.subject} onChange={(e)=>updateContactFormVal('subject', e.target.value)} name="subject" placeholder="Subject"/>
                            </div>

                            <div className="col-md-12">
                                <textarea className="form-control" autoComplete='off' required value={contactUsApplication.message} onChange={(e)=>updateContactFormVal('message', e.target.value)} name="message" rows={3} placeholder="Message"/>
                            </div>

                            <div className="col-md-12 text-center">
                                <button type="submit" disabled={contactUsProcessing} className='bg-main'>{contactUsProcessing ? 'Submitting...' : 'Send Message'}</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

            </div>

        </section>

        <div id="footer" className="footer">
            <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-lg-12 text-center">
                        <h4>Our Newsletter</h4>
                        <p>Subscribe to get all the latest updates from our community.</p>
                    </div>
                    <div className="col-lg-6">
                        <form onSubmit={(ev)=>submitNewsletterEmail(ev)}>
                            <input type="email" required autoComplete='off' name="email" value={newsletterEmail} onChange={(ev)=> setNewsletterEmail(ev.target.value)} placeholder="name@example.com"/>
                            <input type="submit" disabled={newsletterProcessing} className='bg-main' value={newsletterProcessing ? 'submitting' : 'Subscribe'} />
                        </form>
                    </div>
                    </div>
                </div>
            </div>

            <div className="footer-top" style={{backgroundImage: `url(${footerImg})`}}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-5 col-md-12 footer-info">
                            <Logo />
                            <p>A one stop easy solution to prepare for interviews and apply for your dream job. You will get guidance and skills through a systematic and elucidated medium . we strive to enable seniors/industry leaders to share their impactful practical knowledge to aspiring new blood
                            </p>
                            <div className="social-links mt-3">
                                {socialMediaVal.map((value,i)=> <a href={value.link} className="px-2 twitter" target="_blank" rel="noreferrer" key={i}><i className={value.icon} /></a>)}
                            </div>
                        </div>
                        <div className="col-lg-2 col-6 footer-links">
                            <h4>Useful Links</h4>
                            {primaryRoutes.map((value, i )=>{
                                return (
                                    <ul key={i}>
                                        <li className='my-1'><a href={value.path}>{value.title}</a></li>
                                    </ul>
                                )
                            })}
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4>Other documents</h4>
                            {footerSubsetListRoutes.map((value, i)=>
                                <ul key={i}>
                                    <li className='my-1'><a href={value.path}>{value.title}</a></li>
                                </ul>
                            )}
                        </div>

                        <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                            <h4>Contact Us</h4>
                            <p>
                                Rohini, North Delhi, New Delhi-110042
                                <br/>
                                <strong>Phone:</strong> +91-8580543034<br/>
                                <strong>Email:</strong> care@yoursthatsenior.com<br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>YoursthatSenior</span></strong> {currentYear}. All Rights Reserved
                </div>
                <div className="credits">
                    Designed with <i className="fa fa-heart text-danger px-1"/> and <i className="fa fa-laptop text-dark px-1"/>
                </div>
            </div>
        </div>


    </div>
    );
}

export default memo(Footer)