import React from 'react';

import { 
    Home, Maintenance, CourseView, Support, About, NotFound, Blog, Career, 
    BlogDetails, Ticket, Checkout, Legals, Registration, BookCounselling, CourseDGM 
} from './views';

import {
    DISCLAIMER_HTML_CONTENT, PRIVACY_POLICY_HTML_CONTENT, 
    REFUND_AND_SCHEDULE_POLICY, TERMS_OF_SERVICE_HTML_CONTENT 
} from './views/legals';

import { RazorpayPaymentPage } from './views/RazorpayPaymentPage';
import { AppPaths } from './yts.types';

export const appRoutes:AppPaths[] = [
    { displayOnHeader: true, hideForLoggedInUser: false, icon:"", title:'Home', path: '/', component: <Home/>, exact: true },
    { displayOnHeader: true, hideForLoggedInUser: false, icon:"", title:'About us', path: '/about', component: <About/>, exact: false },
    { displayOnHeader: true, hideForLoggedInUser: false, icon:"", title:'Digital Marketing (new)', path: '/digital-marketing', component: <CourseDGM />, exact: false, },
    { displayOnHeader: true, hideForLoggedInUser: false, icon:"", title:'Courses', path: '/courses', component: <CourseView />, exact:false},
    { displayOnHeader: true, hideForLoggedInUser: false, icon:"", title:'Our blogs', path: '/blog', component: <Blog/>, exact: false },
    { displayOnHeader: false, hideForLoggedInUser: false, icon:"", title:'', path:'/blog/:slug', component: <BlogDetails/>, exact: false },
    { displayOnHeader: false, hideForLoggedInUser: false, icon:"", title:'Raise a ticket', path: '/ticket', component: <Ticket/>, exact: false, },
    { displayOnHeader: false, hideForLoggedInUser: false, icon:"", title:'', path:'/checkout', component: <Checkout/>, exact: false },
    { displayOnHeader: false, hideForLoggedInUser: false, icon:"", title:'Careers', path: '/career', component: <Career/>, exact:false},
    { displayOnHeader: true, hideForLoggedInUser: false, icon:"", title:'FAQs', path: '/support', component: <Support/>, exact:false},
    { displayOnHeader: false, hideForLoggedInUser: false, icon:"", title:'', path: '*', component: <NotFound/>, exact: false },
    { displayOnHeader: false, hideForLoggedInUser: true, icon:"", title:'Book your call', path: '/registration', component: <Registration />, exact: false, isSpecial: true},
    { displayOnHeader: true, hideForLoggedInUser: true, icon:"", title:'Get free counselling', path: '/counselling', component: <BookCounselling />, exact: false, isSpecial: true},
    { displayOnHeader: false, hideForLoggedInUser: false, icon:"", title:'', path: '/payment', component: <RazorpayPaymentPage />, exact: false},
    { displayOnHeader: false, hideForLoggedInUser: false, icon:"", title:'Disclaimer', path: '/disclaimer', component: <Legals title='Disclaimer' content={DISCLAIMER_HTML_CONTENT}/>, exact: false, footerSubList: true},
    { displayOnHeader: false, hideForLoggedInUser: false, icon:"", title:'Terms of service', path: '/terms-of-service', component: <Legals title='Terms of service' content={TERMS_OF_SERVICE_HTML_CONTENT}/>, exact: false, footerSubList: true},
    { displayOnHeader: false, hideForLoggedInUser: false, icon:"", title:'Privacy policy', path: '/payments-and-refund', component: <Legals title='Privacy policy' content={PRIVACY_POLICY_HTML_CONTENT}/>, exact: false, footerSubList: true},
    { displayOnHeader: false, hideForLoggedInUser: false, icon:"", title:'Payment terms', path: '/privacy-policy', component: <Legals title='Payment and refund policy' content={REFUND_AND_SCHEDULE_POLICY}/>, exact: false, footerSubList: true},
];

export const MaintenanceRoutes:AppPaths[] = [
    {hideForLoggedInUser:false, displayOnHeader:false, path: '/', component: <Maintenance/> },
];
