import { API_NEWSLETTER_BASE_URL } from "../../environment";
import { IContactUsDetails, ITicketDetails} from "../yts.types";
import { http } from "./api";

const _http = http()

export const submitTicket = (user:ITicketDetails)=> {
  const baseURL = `${API_NEWSLETTER_BASE_URL}/ticket`;
  return _http.post(baseURL, user);
}

export const subscribeToNewsletter = (email:string) => {
  const baseUrl = `${API_NEWSLETTER_BASE_URL}/subscription`;
  return _http.post(baseUrl, {email: email});
}

export const submitContactUsApplication = (body: IContactUsDetails) => {
  const baseURL = `${API_NEWSLETTER_BASE_URL}/contact`;
  return _http.post(baseURL, body);
}