import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDynamicNavbarLinks } from '../../yts.types';
import { AppState } from '../app.store'

interface IHeaderState {
  displayHeader:boolean,
  dynamicHeaderLinks?:IDynamicNavbarLinks[],
  dynamicHeaderLinksPosition?:number,
}

const initialState: IHeaderState = {
  displayHeader: true,
  dynamicHeaderLinks:[],
  dynamicHeaderLinksPosition: 0
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    showHeader: state => {
        state.displayHeader = true
    },
    hideHeader: state => {
      state.displayHeader = false;
    },
    resetDynamicNavbarLinks: state => {
      state.dynamicHeaderLinks = [],
      state.dynamicHeaderLinksPosition = 0; 
    },
    setDynamicNavbarLinks: (state, action:PayloadAction<{links:IDynamicNavbarLinks[], position:number}>)=>{
      const {links, position} = action.payload;
      state.dynamicHeaderLinks = links;
      state.dynamicHeaderLinksPosition = position;
    }
  }
})

/**--------------actions--------------*/
export const { showHeader, hideHeader, resetDynamicNavbarLinks, setDynamicNavbarLinks } = headerSlice.actions

/**--------------selectors--------------*/
export const displayHeader = (state: AppState) => state.header.displayHeader;
export const dynamicHeaderLinks = (state: AppState) => state.header?.dynamicHeaderLinks || [];
export const dynamicHeaderLinksPosition = (state:AppState) => state.header?.dynamicHeaderLinksPosition || 0;

/**--------------reducers--------------*/
export default headerSlice.reducer