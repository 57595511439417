import React, { memo } from 'react'

import certificate from '../../../assets/faq/certificate.png'
import interview from '../../../assets/faq/interview.png'
import grade from '../../../assets/faq/grade.png'
import effort from '../../../assets/faq/effort.svg'
import resume from '../../../assets/faq/resume.svg'
import edc_logo from '../../../assets/faq/edc_logo.jpeg';
import { IFeaturesDetails } from '../../yts.types';

const WhyNotYTS: React.FC = () => {

  const featureDetails:IFeaturesDetails[]= [
    {title:'Looking just for a certificate', description:'This is 2022, not 1975 when just a degree was enough to land in your dream  job. Culture has changed a lot in the last few decades, we will brush up on your skills to get your first job.',photo: certificate, classes:'bg-info text-white'},
    {title:'If you trust unapproved organizations.', description:'YoursthatSenior is government recognized & well-regulated startup. We are incubated at EDC - Entrepreneurial Development Council with best in class product standards, reviews and entrepreneurial ecosystem.', photo: edc_logo, classes:'bg-secondary text-white', imageClass: 'rounded-circle'},
    {title:'Looking for Ready made Interview answers', description:'So, an Interviewer can catch you in the very first line, if your answers are copied from any website/ book/ preparation kit. We train you to express your answers in the best way.',photo: interview, classes:'bg-success text-white'},
    {title:'Looking for a Job without effort from your side.', description:'When you talk about jobs, they are available, but candidates don’t have enough skill-set to get one, so it is very important you learn these basic skills to land your first job.',photo: effort, classes:'bg-dark text-white'},
    {title:'You think Grades will land you a Job.', description:'2.58L students scored 90% in 2021, and above in CBSE exams, so definitely the numbers are huge but when you talk about skilled candidates, the gap is so high, so only grades won’t land you in a job, learn the skills which definitely will.', photo: grade, classes:'bg-warning text-main'},
    {title:'Looking for ready made CVs and Resume and LinkedIn profiles.', description:'Shortlisting of profiles is done with the help of AI software nowadays. We will handhold you on how to customize your profile according to your Job profile.', photo:resume, classes:'bg-main text-white'},
  ]

  return (
    <>
      <section id="faq" className="faq">
        <div className="container"  >
          <header className="section-header">
            <p>Why Not YTS!!</p>
          </header>
          <div className="row d-flex justify-content-center align-items-center">
            {featureDetails.map((item, index) => {
              return (
              <div className="col-sm-12 col-md-6 h-100" key={index}>
                <div className={`${item.classes} card border-0 shadow-sm p-4 mb-3`} >
                  <div className="row">
                    <div className='col-md-6 col-lg-8 col-sm-12'>
                      <h4 className='font-size-18'><strong>{index<9 ? `0${index+1}`:`${index+1}`}</strong></h4>
                      <h3 style={{fontSize:'20px'}}><strong>{item.title}</strong></h3>
                      <p className='font-size-14'>{item.description}</p>
                    </div>
                    <div className='col-lg-4 col-md-6 d-none d-md-block'>
                      <div className={item?.imageClass ? item.imageClass  : ''} style={{backgroundImage:`url(${item.photo})`, backgroundRepeat:'no-repeat', backgroundSize:'contain', width:'160px', height:'160px' , backgroundPosition:'center'}}/>
                    </div>
                  </div>
                </div>
              </div>
              )
            })}
          </div>

        </div>
      </section>
    </>
  )
}

//to be exported somewhere else

export default memo(WhyNotYTS);