import { ToastOptions } from "react-toastify";
import { ICourseChapterDetails, IFaq } from "../yts.types";
import { QueryClientConfig } from "react-query";

export const queryClientConfig: QueryClientConfig = {
    defaultOptions: {
        queries: {
            enabled: true,
            staleTime: 1000 * 60 * 5,
            cacheTime: 1000 * 60 * 5,
            retry: 2,
            retryDelay: 1000,
            refetchInterval: 1000 * 60 * 5,
            refetchOnWindowFocus: false,
        }
    }
}

export const DEFAULT_BLOG_PAGE_SIZE = 12;

export const DEFAULT_COURSE_PRICE = 5000;

export const DEFAULT_TOAST_OPTIONS:ToastOptions = { hideProgressBar: true, theme:'dark'};
export const SUCCESS_TOAST_OPTIONS: ToastOptions = { hideProgressBar:true, theme:'light', closeButton: false, type:'success'};
export const INFO_TOAST_OPTIONS: ToastOptions = { hideProgressBar:true, theme:'light', closeButton: false, type:'info'};
export const ERROR_TOAST_OPTIONS:ToastOptions = { type: 'error', hideProgressBar: true, theme:'light', closeButton: true };
export const WARNING_TOAST_OPTIONS:ToastOptions = { type: 'warning', hideProgressBar: true, theme:'light', closeButton: false };

export const dynamicScriptIngestionList = [
    {
        src:"https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js",
        key: 'popperJs', attributes:{
            integrity:"sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1", crossorigin:"anonymous"
        }
    },
    {
        src:"https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js",
        key: 'bootstrap', attributes:{
            integrity:"sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM", crossorigin:"anonymous"
        }
    }
]

export const stateList = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
]

export const courseFaq:IFaq[]= [
    {
        question:'Is the free version of the course available on Yoursthatsenior?',
        answer:"No, the free version of the course is not available for Yoursthatsenior, but you can try a demo class by registering on the website. Other than that, there are no demo classes for live sessions, and we can see the recording of the previous lectures, which has happened in the past."
    },
    {
        question:"Who are the teachers at YoursthatSenior? What is their background?",
        answer:'All of our teachers and mentors at YoursthatSenior have strong backgrounds in training corporates and students from the last five years. You can check their description to know our mentors as well.'
    },
    {
        question:'How to raise my doubts at YoursthatSenior?',
        answer:'You can always reach out to us at care@yoursthatsenior.com. A second option is to schedule a one-on-one session with our mentors in the videos. Such a session would still cost you money but will prove to be an asset. Other than that, you can ask all the questions on our community platform. This is where the people from top institutes like IIT and I will listen to you.'
    },
    {
        question:' Can multiple people learn from my course at YoursthatSenior?',
        answer:"No, what is the benefit of it if someone is paying and the other one is using it. If any such thing occurs you can always write to care@yoursthatsenior.com for bumper discounts."
    },
    {
        question:'Can I download content provided by YoursthatSenior?',
        answer:' No, we do not recommend downloading our content, and if we are able to track it down you will be bound to legal actions.'
    },
    {
        question:'Are live Sessions happening daily?',
        answer:"At YoursthatSenior live sessions happen twice a week. One for theoretical concepts and other for activities for a gamified learning experience."
    },
    {
        question: "How will I get to know about live sessions at YoursthatSenior?",
        answer:"You will be receiving notifications on your email as well as on the phone from YoursthatSenior. We ensure that you don’t miss any opportunity to learn with us."
    },
    {
        question: "What if I miss any of the live sessions?",
        answer:"All the live sessions are recorded. If you miss because of any important reason you can always write at care@yoursthatsenior.com to get the recording access."
    },
    {
        question: "What if I am not able to submit my project on time?",
        answer:"You will be rewarded with lesser marks because time management is always the key."
    }, 
    {
        question:'Can I take a demo class of your course?',
        answer:"Demo’s are unavailable right now. But you can always get a chance to be in our exclusive webinars at YoursthatSenior."
    }, 
    {
        question:"Can I watch your videos for free for the first month, and then I can pay for the same?",
        answer:"No, that option is not available because there are so many projects and activities from the first week itself, and to participate you need to register."
    }, 
    {
        question:'How can I contact the teacher for personal sessions?',
        answer:"If you wish to have one-on-one sessions with the teachers, you can always write to care@yoursthatsenior.com ."
    }, 
    {
        question:"How much time is required to complete my course?",
        answer:"Our courses are designed in a way where you can learn maximum. Each certification course at YoursthatSenior has a minimum duration of 2 months to transform you into a charming personality."
    }, 
    {
        question:"What is the duration of your courses?", 
        answer:"The course will last a minimum of 60 days and a maximum of 90 days, depending on how quickly you complete the course, projects and activities."
    },
    {
        question:"Is education adaptable at YoursthatSenior? Is it true that if I have exams in between, I can stop the course?",
        answer:"Yes, our education is adaptable. You can pause learning if you have other obligations, but you must complete the activities that are assigned once you have finished your exams or something similar. Nonetheless, they are required in order to obtain the certifications you will receive during the course."
    },

]

export const certificateFaq:IFaq[]= [
    {
        question:'How many certificates will I get from these courses of Placement Preparation Pack and Corporate pack at YoursthatSenior?',
        answer:'From this course, you will get two certificates, one for the course you are taking, and the other for the personality development test.'  
    },
    {
        question:'What is the credibility of the certificate provided by YoursthatSenior?',
        answer:'YoursthatSenior is an approved company, and its certificate is accepted across the country. In addition, our mentors have the experience of working in reputed firms and educational institutes with a minimum working experience of five years.'
    },
    {
        question:'Can YoursthatSenior provide two certificates for one enrollment like I want the certificate for my brother or sister as well?',
        answer:"No, only the student who will enroll will be provided with the certificate from YoursthatSenior."
    },
    {
        question:"What are the judging criteria for the certificates to be given at YoursthatSenior?",
        answer:'The parameters at YoursthatSenior are writing skills, presentation skills, confidence level, and the Plagiarism of the report.'
    },
    {
        question:"Will I get any physical certificate or will it be delivered to my mail ID?",
        answer:"YoursthatSenior do not send physical certificates; instead, your certificate will be sent to your email address, and you can have it printed on any type of hard paper."
    }, 

]

export const otherFaq:IFaq[]=[
    {
        question:'Is YoursthatSenior government-approved?',
        answer: "Yes, YoursthatSenior is a government-approved organization. You can always check the authenticity on the Ministry of Corporate affairs portal."
    },
    {
        question:'My video is not working while watching Placement Preparation Pack or Corporate Training Pack?. What should I do?',
        answer:'Firstly clear your cache, reset your password and then try again. Your video will be working if the issue still persists. You can write to care@yoursthatsenior.com.'
    },
    {
        question:'I want the number of YoursthatSenior support team?', 
        answer:"You can directly write an e- mail to care@yoursthatsenior.com and drop your number. They will feel happy to reach out to you in the next 48 hours."
    }, 
    {
        question:"What are the benefits of a subscription at YoursthatSenior?",
        answer:"Our subscription will enable you to get live job updates, industry specific interview training, mock interviews and unlimited competition access."
    },
    {
        question:"Do you provide any kind of personal coaching for things like SSB UPSC interviews?",
        answer:"Yes, we offer SSB UPSC interview preparation and coaching. Simply send an email to care@yoursthatsenior.com, and we will provide you with the best solution."
    }
]

export const paymentsFaq:IFaq[]=[
    {
        question:'Can I pay in EMI to avail any Course at YoursthatSenior?',
        answer:'You can pay via EMI at YoursthatSenior, but not via your UPI handle. You can pay via debit card or credit card using EMI.'
    },
    {
        question:'I am not satisfied with the course provided by YoursthatSenior. I want my money back. What is the refund process?',
        answer:"We highly regret the inconvenience caused to you. In any case if you are not able to continue you can get a refund under 7 days or 2 classes (Whichever happens first). You just need to write to care@yoursthatsenior.com."
    },
    {
        question:"What if I don't enjoy your class? Will YoursthatSenior give me a complete refund?",
        answer:"YoursthatSenior only refund money from the first day of enrollment to the Seventh day of enrollment or 2 classes whichever happens earlier."
    },
    {
        question:"What is the cost of personal sessions with the mentors in the course?",
        answer:"The cost of the individual sessions with the course mentors differs from mentor to mentor, so you can always write an email to care@yoursthatsenior.com for more information."
    },
    {
        question:"One of my friends also wants to join the course at YoursthatSenior. Will he get any discount?",
        answer:"Your friend can join directly from our website, and if he wants a discount or scholarship, you can always write at care@yoursthatsenior.com -stating that you are referring him."
    }
]

export const accountFaq:IFaq[]=[
    {
        question:'How to reset my profile password of YoursthatSenior account?',
        answer:'Go to your login section on the homepage of YoursthatSenior you will find reset password link there.'
    },
    {
        question:'How to log in to the course for the first time?',
        answer:"You will receive login credentials and a link in your email from YoursthatSenior once you have paid for our courses. Then, using that link, login and reset your password, you can begin your learning journey with us"
    },
    {
        question:'Can I share my YoursthatSenior credential with my friends?',
        answer:"Yes, you may always share, but to get proper certification your friends need to subscribe to our course."
    }
]

export const CourseChapterDetails: ICourseChapterDetails[] = [
    {
        title: 'Full stack developer employability bootcamp',
        excerpt: `
            Our full stack developer program offer complete set of software development skills, and empowers students with various skills, tool
            and technologies with guaranteed placement and internship opportunities.
        `,
        chapters: [
            {
                title: 'Project and process management',
                description: `
                    Learn techniques and methods for effective, efficient & tips for maximizing throughput during project management cycle. Tricks & tips 
                    for your freelancing and academic projects. 
                `,
                questions: [
                    'Agile Methodologies',
                    'Scrum Methodologies',
                    'HTTP & Computer networks basics',
                    'Web 2.0',
                    'Software development life cycle & models'
                ]
            },
            {
                title: 'Version control and open-source contribution',
                description: 'Learn and understand basic of below mentioned tools with industry examples and practice them on day-to-day basis during the program.',
                questions: [
                    'VCS - Git',
                    'GitHub',
                    'Open-source contribution',
                    'Code management and organization techniques',
                    'GitOps'
                ]
            },
            {
                title: 'Basic frontend development and Web design',
                description: `
                    Get in-depth knowledge of languages and methods for modern, user-friendly, responsive website development. 
                    Build projects and portfolio website to maximize learning during the program.
                `,
                questions: [
                    'HTML-5',
                    'Semantic HTML & SEO rules',
                    'CSS3',
                    'Javascript',
                    'Bootstrap',
                    'Flexbox',
                    'Advance CSS, Font & icon libraries for real work projects'
                ]
            },
            {
                title: 'Advance frontend frameworks and state management',
                description: `
                    Enjoy hands-on coding experience with cutting-edge modern day technologies like React & build projects with industry code 
                    standards using typescript, hooks and redux.
                `,
                questions: [
                    'React JS',
                    'Basics of Typescript',
                    'React with typescript',
                    'React Hooks',
                    'React & Redux tools for state management'
                ]
            },
            {
                title: 'Backend development',
                description: `
                    Understand and assimilate basic server design, backend development using tools mentioned below for modern day large scale systems. 
                    Hands-on practice of building REST APIs and scalable databases.
                `,
                questions: [
                    'REST API',
                    'Node JS',
                    'Express JS',
                    'Mongo DB',
                    'MySQL',
                ]
            },
            {
                title: 'Advance backend methodologies',
                description: '',
                questions: [
                    'NodeJS with Typescript',
                    'API security & authentication mechanisms',
                    '3rd Party service integration',
                    'MVC and code base management methods',
                    'Promises & Async-await approach',
                    'Multi-threading',
                ]
            },
            {
                title: 'DevOps & Cloud technologies',
                description: '',
                questions: [
                    'Heroku',
                    'Netlify',
                    'AWS basics',
                    'Mongo Atlas & cluster management',
                    'CI-CD & pipelines'
                ]
            },
            {
                title: 'Projects & Tasks',
                description: 'Build modern day projects that adds value to your project and practice Full-stack development with our live classes and weekly assignments that will help you outshine peers and ace jobs.',
            },
            {
                title: 'DSA brainstorming sessions',
                description: 'YoursthatSenior will organize in-house competitive programming sessions with peers on frequent basis, with medium & easy DSA problem solving to help students prepare for coding interview rounds.',
            },
            {
                title: 'Resume building and LinkedIn optimization',
                description: 'Our experts with hand-hold students for resume making and provide them with 1:1 mentorship. Students will be provided with industry-grade resume, keywords and projects descriptions to exponentially increase their chances of selection.',
            },
        ]
    },
    {
        title: 'Placement Preparation Workshop',
        excerpt: "A comprehensive live training to cater all your job requirements. From resume preparation to interview hacks, we cover all aspects of job preparation with dedicated industry specific mentors.",
        chapters: [
            {
                title: 'Live Interacting Training',
                description: 'Six hours of live content and two hours of question and answer time make up the eight hours of the learning journey, which is spread out over two weeks',
                questions: [
                    'Devoted coaches with one-on-one question sessions',
                    'Training on Resume building',
                    'Linkedin profile Optimization',
                    'Mock Interviews, group discussion, and guesstimates',
                    'Beat the heat of unemployment with a mentor of your choice',
                ]
            },
            {
                title: 'Exclusive Content with life long access',
                description: 'Get lifetime accessibility to special content that will assist you at every stage of your existence to succeed in any endeavour',
                questions: [
                    '3 ATS Friendly resume template',
                    '2 Industry specific interview question bank',
                    'Resume Action verbs cheat sheet',
                    '1000+ preconfigured resume pointers for specific domains.',
                    'Interview hack sheet.',
                ]
            },
            {
                title: 'Industry specific mentors',
                description: 'Acquire concrete guidance from five or more mentors regarded as experts in your chosen field for better delivery and comprehension.',
                questions: [
                    'Engineering',
                    'Language and Literature',
                    'Management',
                    'Human Resource',
                    'Finance and Project Management',
                ]
            },
            {
                title: 'Access to Community',
                description: 'Get special access to the community and benefits like:',
                questions: [
                    'What is Leadership', 
                    'Leadership Skills',
                    'Explanation',
                    'Other activities',
                ]
            },
            {
                title: 'Mock Interview',
                description: 'Avoid getting too pumped up to attend your next interview.',
                questions: [
                    'Get a free 30-minute HR interview round', 
                    'Practise your SWOT',
                    'Learn Advance techniques like 4K apporach',
                ]
            },
        ]
    }
]

export const AVAILABLE_COURSES = [
    {
        title: 'Full stack developer employability bootcamp', 
        key: 'online_full_stack_developer_program',
        features: [
            'HTML-5', 'CSS3', 'JavaScript', 'Bootstrap and flexbox', 'React Js', 'Redux', 'HTTP', 'Web 2.0', 'Rest API', 'Node JS', 'Express', 'MongoDB', 'Agile & Scrum', 'VCS - Git', 'GitHub', 'CI & CD basics', 'MongoDB & MYSQL', 'Heroku and netlify', 'AWS basics'
        ],
        description: `
            Our full stack developer program offer complete set of software development skills, and empowers students with various skills, tool
            and technologies with guaranteed placement and internship opportunities.
        `
    },
    {
        title: 'Placement Preparation Workshop', 
        key: 'online_soft_skills_course',
        features: ['Live classes', 'Resume building', 'Linkdin optimization', 'Industry specific question bank', 'Mock interviews', 'Psychometric evaluation', '24*7 Support'],
        description: `A comprehensive live training to cater all your job requirements. From resume preparation to interview hacks, we cover all aspects of job preparation with dedicated industry specific mentors.`
    },
]

export const RAZORPAY_PAYMENT_LINK_PREFIX =  'https://rzp.io/l/';