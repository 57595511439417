import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./index.css";

import App from "./app/App";
import { QueryClient, QueryClientProvider } from "react-query";
import { queryClientConfig } from "./app/util/constants";
const queryClient = new QueryClient(queryClientConfig)

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>

  </React.StrictMode>,
  document.getElementById("root")
);
