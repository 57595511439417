export const LOCAL_STORAGE_VARIABLE = {
    AUTH_TOKEN: 'yts_auth_token',
    BLOG_VIEW_MODE: 'yts_blog_view_mode',
    LAST_SELECTED_COURSE: 'yts_last_selected_course_name'
}

export const setStorageItem =(key:string, value:string):void=>{
    window.localStorage.setItem(key,value);
}

export const getStorageItem = (key:string) => {
    return window.localStorage.getItem(key) || null;
}

export const removeStorageItem = (key:string):void => {
    window.localStorage.removeItem(key);
}
