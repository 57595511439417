import { lazy } from 'react'

export const HomeHero = lazy(() => import('./home/hero'));
export const About = lazy(() => import('./home/about'));
export const Counts = lazy(() => import('./home/counts'));
export const WhyNotYTS = lazy(() => import('./home/whyNotYTS'));
export const Footer = lazy(() => import('./home/footer'));
export const Navbar = lazy(() => import('./navbar'))
export const Modal = lazy(() => import('./Modal'));
export * from './blogs';
export * from './Accordion'