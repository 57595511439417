import { toast } from "react-toastify";
import { ERROR_TOAST_OPTIONS } from "../../util/constants";

export const handleHttpError = (err:any, errMsg?:string) => {
    let { response } = err;
    response = response?.data;
    if(err?.message == 'Network Error'){
        errMsg = 'OOPS! Internal server error, Try again later';
    }
    if(Array.isArray(response?.message)){
        (response?.message || []).forEach((er:string) => toast(er, ERROR_TOAST_OPTIONS));
        return;
    }
    toast(response?.message || errMsg || 'Something went wrong, please try again.', ERROR_TOAST_OPTIONS);
}
