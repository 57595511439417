import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { handleHttpError } from '../../service/api/httpHandler';
import { getBlogPosts } from '../../service/blog.service';
import { DEFAULT_BLOG_PAGE_SIZE } from '../../util/constants';
import { IBlogPost, IBlogPostsResponse } from '../../yts.types';
import { AppState } from '../app.store'

interface IBlogState {
  blogs: IBlogPost[],
  current_page:number,
  loading:boolean,
  items_per_page: number,
  recent_blogs: IBlogPost[], 
  trending_blog_topics: string[],
}

const initialState: IBlogState = {
  blogs:[],
  current_page: 1,
  items_per_page: DEFAULT_BLOG_PAGE_SIZE,
  recent_blogs:[],
  trending_blog_topics:[],
  loading: false,
};

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setBlogsLoading: (state, action:PayloadAction<{loading:boolean}>)=>{
        state.loading = action.payload.loading;
    },
    setBlogPageItemLength:(state,action:PayloadAction<{items_per_page:number}>)=>{
        state.items_per_page = action.payload.items_per_page < DEFAULT_BLOG_PAGE_SIZE ? state.items_per_page : action.payload.items_per_page;
    },
    getBlogItems: (state)=> {
        //! we should check for cache!!
        setBlogsLoading({loading: true});
        getBlogPosts().then((val:IBlogPostsResponse) => {
            setBlogItems({blogs: val.postsConnection.edges.map(el => el.node)});
            // we must have out own system for identifying the trending topics. logic below is stupid.
            setTrendingBlogTopics({list: state.blogs.map(el => el.title)});
            setBlogsLoading({loading: false});
        }).catch(err => {
            console.log(err);
            handleHttpError(err);
            setBlogsLoading({loading: false});
        });
    },
    setBlogItems: (state, action:PayloadAction<{blogs:IBlogPost[]}>)=>{
        state.blogs = action.payload.blogs;
    },
    getMoreBlogItems:(state, action:PayloadAction<{page:number}>)=>{
        setBlogsLoading({loading: true});
        // we need pagination in the blog service to get the blogs for specific page number.
        state; action.payload.page;
        setBlogsLoading({loading: false});
    },
    unshiftRecentBlog:(state, action:PayloadAction<{blog: IBlogPost}>)=>{
        const idx = state.recent_blogs.findIndex(el => el.slug == action.payload.blog.slug);
        if(idx !== -1) state.recent_blogs.splice(idx,1);
        else state.recent_blogs.splice(4); // removing the last item, if exist.
        state.recent_blogs.unshift(action.payload.blog);
    },
    setTrendingBlogTopics:(state, action:PayloadAction<{list?:string[]}>)=>{
        if(action.payload?.list) {
            action.payload.list.splice(5);
            state.trending_blog_topics = action.payload.list;
        }
    },
    openBlogArticle:(state, action:PayloadAction<{slug:string}>)=>{
        const blog = state.blogs.find(el => el.slug === action.payload.slug);
        if(blog) unshiftRecentBlog({blog: blog});
    },
    setCurrentPageNumber:(state)=>{
        getMoreBlogItems({page: state.current_page++});
        state.current_page = state.current_page++;
    }
  }
})

/**--------------actions--------------*/
export const { setBlogPageItemLength, getBlogItems,openBlogArticle, getMoreBlogItems, setBlogItems, unshiftRecentBlog, setBlogsLoading, setCurrentPageNumber, setTrendingBlogTopics} = blogSlice.actions

/**--------------selectors--------------*/
export const blogs = (state: AppState) => state.blog.blogs;
export const currentPage = (state: AppState) => state.blog.current_page || [];
export const isBlogLoading = (state:AppState) => state.blog.loading || false;
export const itemsPerPage = (state:AppState) => state.blog.items_per_page ||  DEFAULT_BLOG_PAGE_SIZE;
export const recentBlogs = (state:AppState) => state.blog.recent_blogs || [];
export const trendingBlogTopics = (state:AppState) => state.blog.trending_blog_topics || [];

/**--------------reducers--------------*/
export default blogSlice.reducer;