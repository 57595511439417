import value1 from '../../assets/values-1.png'
import value2 from '../../assets/values-2.png'
import value3 from '../../assets/values-3.png'

const ORGANIZATION_WHATSAPP_NUMBER = '+919650455160';

export const HOMEPAGE_CONSTANTS = {
    countsValues :[
        {icon: 'far fa-smile', title: 'Happy clients', end:150},
        {icon: 'fas fa-file-contract', title: 'Projects', end:521, color:'#ee6c20'},
        {icon: 'fas fa-handshake', title: 'Hours of support', end:1463, color:'#15be56'},
        {icon: 'fas fa-users', title: 'Hand worker', end:15, color:'#bb0852'},
    ],
    valueVal:[
        {image:value1, heading:'Confidence', description:'We ensure and empower individuals with the right opportunities to be confident in every scenario.'},
        {image:value2, heading:'Employability', description:'With YoursthatSenior, Learn 21st century skills that enable you to achieve your dream career.'},
        {image:value3, heading:'Growth', description:'We enhance and embed values to ensure gradual development and progress in your career goals.'},
    ],
    socialMediaIcons:[
        {icon: 'fab fa-instagram', link:'https://www.instagram.com/yours_thatsenior/'},
        {icon: 'fab fa-linkedin', link:'https://www.linkedin.com/company/yoursthatsenior/'},
        {icon: 'fab fa-facebook', link:'https://www.facebook.com/profile.php?id=100075014881878'},
        {icon: 'fab fa-twitter', link:'https://twitter.com/YoursThat'},
        {icon: 'fab fa-whatsapp', link:`https://api.whatsapp.com/send?phone=${ORGANIZATION_WHATSAPP_NUMBER}&text=Hello YoursthatSenior, I want to get in touch with yours organization regarding...`},
        {icon: 'fab fa-google-plus-g', link:'mailto:care@yoursthatsenior.com?subject=Enquiry from your name&body=I want to get in touch with yours organization regarding...'},
    ],
    vision:{
        heading:'Our Vision',
        description:`<strong>YoursthatSenior: The one who guides you.</strong> Our vision is to enhance the growth of students and early-stage employees in terms of personal development and soft skills.<br/>
        We create a <strong>personalized community</strong> for our students across the globe, providing them with exemplary guidance related to examinations, assignments, interviews, resume building, Linkedin profile setup, career assistance, etc.<br/>
        We, at YoursthatSenior, believe in <strong>Gamified Learning</strong> experience. This helps us instil significant skills with ease. We are here to fill the scarcity of a perfect senior who is going to assist you with all your career decisions and help you attain perfection.<br/>
        Language, city, or even your college isn’t a barrier here. If you are someone whose purpose is to bring a change, then, you’re already the one we are looking for.`
    },
    mission:{
        heading:'Our Mission',
        description:`India has <strong>more than 55,500</strong> higher education institutes out of which <strong>3800+ have a NIRF rating.</strong><br/>
        YoursthatSenior comes with a promise of providing equal opportunity to every class and student to work towards their ambition. We strive to cater to the needs of <strong>45,000+ Tier 2 </strong>or below students who often suffer from a void of proper guidance in their professional environment.<br/>
        Our mission is to bridge the gap between successful seniors and youth to work towards success and growth.<br/>
        Our focus is on underpowering your weaknesses and bringing your capabilities into the limelight. Our curriculum involves live training in<strong> public speaking, leadership, confidence, team building, critical thinking, decision making, real-time challenges, and competitions that will help you to upskill.</strong>`
    }
}