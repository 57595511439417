import React from 'react'

export const Accordion = (props:{list:Array<{question: string, body: string, points?:string[], pointsHeader?:string}>, id:number}) => {
    const {list, id } = props;
    return (
      <div className="accordion accordion-flush">
        {list.map((value, idx) => {
          return (
            <div className="accordion-item" key={5*id+idx+1}>
              <h2 className="accordion-header" id={`panelsStayOpen-heading${5*id+2*id+idx+1}`}>
                <button className="accordion-button collapsed shadow-none font-size-18" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse${5*id+idx+1}`} aria-expanded="true" aria-controls={`panelsStayOpen-collapse${5*id+idx+1}`}>
                  {value.question}
                </button>
              </h2>
              <div id={`panelsStayOpen-collapse${5*id+idx+1}`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen-heading${5*id+idx+1}`}>
                <div className="accordion-body font-size-15">
                  {value.body}
                  <div>
                    {value.pointsHeader ? <p className='font-size-18 mt-3'><strong>{value?.pointsHeader || ''}</strong></p> : ''}
                    {(value?.points || [])?.length > 0 ? <ul>{(value?.points || [])?.map((pt, index) => <li key={index}>{pt}</li>)}</ul> : ''}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
}
  