import axios from "axios";
import { API_BASE_URL } from "../../../environment";
import { getStorageItem, LOCAL_STORAGE_VARIABLE } from "../../util/storage";

/**
 * Details of use for http() & _http = http();
 * - use http() in case you want to use the updated token from the local storage.
 * - use _http in case of YTS public routes.
 */
export const http = (token = getStorageItem(LOCAL_STORAGE_VARIABLE.AUTH_TOKEN) || 'none') => axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    "Content-type": "application/json",
    "authorization": `${token}`
  }
});