import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { RAZORPAY_PAYMENT_LINK_PREFIX } from '../util/constants';

import bugImg from '../../assets/bugs.svg';

export const RazorpayPaymentPage: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentLink, setPaymentLink] = useState<string>('');
    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        setProcessing(true);
        const query_payment_name = searchParams.get('code');
        if(query_payment_name && query_payment_name.length > 0) {
            const payment_link = RAZORPAY_PAYMENT_LINK_PREFIX + `${query_payment_name.split('_').join('-')}`;
            setPaymentLink(payment_link);
        }
        setProcessing(false);
    }, [searchParams])
    
  return (
    <section>
        {   paymentLink.length > 0 && !processing ? <iframe src={paymentLink} width="100%" height="1280px" frameBorder={0}></iframe>
            : 
            <div className='d-flex align-items-center justify-content-center flex-column'>
                <img src={bugImg} style={{maxHeight:'350px'}} className="m-5 px-5 img-fluid" alt="404 not found on YoursthatSenior."/>
                <p className='lead font-size-18'>Invalid payment link, Please try again.</p>
                <Link to={'/'}>
                    <button className='btn-get-started text-white bg-main py-4 d-inline-flex align-items-center justify-content-center align-self-center'>Back to home</button>
                </Link>
            </div>
        }
    </section>
  )
}