import { request} from "graphql-request";
import { GRAPHCMS_ENDPOINT } from "../../environment";
import { getBlogsQuery, getSingleBlogUsingSlugQuery } from "./query/blog.query";

const blogBaseUrl = GRAPHCMS_ENDPOINT;

export const getBlogPosts = () => {
  return request(blogBaseUrl, getBlogsQuery);
}

export const getBlogUsingSlug = (slug:string) => {  
  return request(blogBaseUrl, getSingleBlogUsingSlugQuery, {slug});
}